import * as React from 'react';
import Box from '@mui/material/Box';
import Stepper from '@mui/material/Stepper';
import Step from '@mui/material/Step';
import StepLabel from '@mui/material/StepLabel';
import DoNotDisturbIcon from '@mui/icons-material/DoNotDisturb';

const style = {
    position: 'absolute',
    top: '50%',
    left: '50%',
    transform: 'translate(-50%, -50%)',
    width: 800,
    bgcolor: 'background.paper',
    border: '2px solid #000',
    boxShadow: 24,
    p: 4,
};

export default function IopStatus({data}) {

    const cause = data?.cause;
    const isFatalFailure = (code) => {
        return ["UNKNOWN_ERROR", "INVALID_REQUEST", "FILE_NOT_FOUND", "EMPTY_FILE", "FILE_EXTENSION_NOT_ALLOWED",
            "UNABLE_TO_VALIDATE_PDF_SIGNATURE","NO_PDF_METADATA_FOUND", "INVALID_XMP_STRUCTURE", "NO_INVOICE_XML_FOUND",
            "INVALID_XML_STRUCTURE", "UNKNOWN_INVOICE_FORMAT",].includes(code);
    };

    let status = [];

    if (data.code && isFatalFailure(data.code)) {
        status.push({name: "Init", error: true});
    } else {
        status.push({name: "Init", error: false});
        status.push({name: "Antivirus", error: data?.code === "FILE_IS_INFECTED" ? true : false});

        if (data?.cause?.signaturesResult?.isSigned || data?.cause?.pipelineResult?.signaturesResult?.isSigned
          || data?.invoiceSignatures?.isSigned) {
            status.push({name: "Signature", error: data?.code === "INVALID_SIGNATURE" ? true : false})
        } else {
            status.push({name: "Signature", icon:<DoNotDisturbIcon/>});
        }

        if (data?.ext.toUpperCase() === "PDF") {
            status.push({name: "PDF/A3", error: cause?.pdfA3ValidationResult ? true : false});
            status.push({name: "PDF Xmp", error: cause?.pdfXmpValidationResult ? true : false});
        }

        status.push({name: "Schema Validation", error: cause?.schemaValidationErrors ? true : false});
        status.push({name: "Schematron Validation", error: cause?.schematronValidationErrors ? true : false});
    }

     return (
        <Box sx={{ width: '100%', align: 'center', mt: 1, mb: 3}}>
            <Stepper activeStep={10}>
                {status && Array.isArray(status) && status?.map((item) => {
                    return (
                        <Step key={item.name}>
                            <StepLabel {...item}>{item.name}</StepLabel>
                        </Step>
                    );
                })}
            </Stepper>
        </Box>
    );
}

/**
 * <CardActions>
 *                 <Button size="small">Learn More</Button>
 *             </CardActions>
 */