import Keycloak from 'keycloak-js';
import config from '../config';

const getKeycloakUrl = () => {
  const host = window.location.host;

  if (host.includes('localhost')) {
    return 'http://localhost:8080';

  } else {
    return 'https://' + host.replace('labs', 'auth');
  }
};

const getRedirectUrl = () => {
  const host = window.location.host;

  if (host.includes('localhost')) {
    return 'http://localhost:4006/';

  } else {
    return 'https://' + host;
  }
};

const _kc = new Keycloak({
  url: getKeycloakUrl(),
  realm: config.keycloak.realm,
  clientId: config.keycloak.clientId
});

let user = {}

/**
 * Initializes Keycloak instance and calls the provided callback function if successfully authenticated.
 *
 * @param onAuthenticatedCallback
 */
const initKeycloak = async (onAuthenticatedCallback) => {
  try {
    const authenticated = await _kc.init({
      onLoad: 'login-required',
      checkLoginIframe: false
    });

    if (!authenticated) {
      console.log('user is not authenticated..!');
      doLogout();

    } else {
      setInterval(() => {
        _kc.updateToken(5)
          .then(() => {
            console.log('token updated');
          })
          .catch(doLogin)
      }, 100000);

      await onAuthenticatedCallback();
    }
  } catch (error) {
    console.error(error);
  }
}

const doLogin = _kc.login;

/**
 *
 * @returns {User}
 */
const getUser = () => {
  return user;
}

/**
 *
 * @returns {User}
 */
const setUser = (usr) => {
  user = usr;
}

const doLogout = () => {
  _kc.token = null;
  _kc.clearToken();
  _kc.logout({redirectUri: getRedirectUrl()});
  _kc.clearToken();
  _kc.token = null;
}

const getToken = () => _kc.token;

const isLoggedIn = () => {
  return !!_kc.token;
}

const updateToken = (successCallback) =>
  _kc.updateToken(5)
    .then(successCallback)
    .catch(doLogin);

const getUsername = () => _kc.tokenParsed?.preferred_username;

const hasRole = (roles) => roles.some((role) => _kc.hasRealmRole(role));

const sandboxActivated = () => user.sandboxStatus === 'CREATED';

const sandboxOngoing = () => user.sandboxStatus === 'ONGOING';

const sandboxClientSecret = () => user.sandboxSecret;

const getEmail = () => user.email;
const sandboxClientId = () => user.email;
const setSandboxOngoing = () => user.sandboxStatus = 'ONGOING';

const UserService = {
  sandboxClientSecret,
  sandboxOngoing,
  setSandboxOngoing,
  sandboxClientId,
  getEmail,
  initKeycloak,
  sandboxActivated,
  doLogin,
  doLogout,
  isLoggedIn,
  getToken,
  updateToken,
  getUsername,
  hasRole,
  getUser,
  setUser
};

export default UserService;