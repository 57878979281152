import React, { memo } from 'react';
import {Handle, Position} from 'reactflow';
import publicBuilding from '../../assets/publicBuilding.png';

function IopPpf({ data, isConnectable}) {
  return (
    <>
      <Handle
        type="target"
        position={Position.Bottom}
        style={{ background: '#555' }}
        onConnect={(params) => console.log('handle onConnect', params)}
        isConnectable={isConnectable}
      />
          <img style={{
            width: 110,
            height: 80,
            padding: 10,
            border: 'groove'
          }} src={publicBuilding}/>
    </>
  );
}
export default memo(IopPpf);
