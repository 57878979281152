import React, {memo} from 'react';
import Button from '@mui/material/Button';
import Stack from '@mui/material/Stack';
import ThumbUpAltIcon from '@mui/icons-material/ThumbUpAlt';
import ThumbDownAltIcon from '@mui/icons-material/ThumbDownAlt';
import {Handle, Position} from 'reactflow';
import HttpService from '../../services/HttpService';
import {toast} from 'react-toastify';
import {DialogActions, DialogContent, DialogTitle, Grid} from '@mui/material';
import Dialog from '@mui/material/Dialog';
import {publish} from '../../controller/event';
import Typography from '@mui/material/Typography';
import DialogContentText from '@mui/material/DialogContentText';
import TextField from '@mui/material/TextField';
import PaymentIcon from '@mui/icons-material/Payment';
import Box from '@mui/material/Box';

function IopReceiver({data, isConnectable}) {
  const [invoiceStatus, setInvoiceStatus] = React.useState('');

  const [invoiceId, setInvoiceId] = React.useState(null);
  const [buttonPayOpen, setButtonPayOpen] = React.useState(false);

  const handlePopupPayOpen = () => {
    setButtonPayOpen(true);
  };

  const handlePopupPayClose = () => {
    setButtonPayOpen(false);
  };

  const handleInvoiceReceived = (event) => {
    setInvoiceId(event?.detail.invoiceId);
    setInvoiceStatus('RECEIVED');
    console.log("Received invoice with id : " + event?.detail?.invoiceId);
  }

  const reset = () => {
    setInvoiceStatus('');
    setInvoiceId(null);
  }

  React.useEffect(() => {
    document.addEventListener('@event/INVOICE_RECEIVED', handleInvoiceReceived); // change made available
    document.addEventListener('@event/RESET_ALL', reset);

    return () => {
      document.removeEventListener('@event/INVOICE_RECEIVED', handleInvoiceReceived);
      document.removeEventListener('@event/RESET_ALL', reset);
    }
  }, []);

  function RenderBtnToPay() {
    return (
      <Grid container spacing={2}>
        <Grid item xs={4}>
          <Typography sx={{width: '15%', flexShrink: 0}}>
            <Button variant="outlined" sx={{width: 120, fontSize: 10, padding: 1, margin: 1}} color="primary"
                    onClick={handlePopupPayOpen}
                    endIcon={<PaymentIcon/>}>
              Payer
            </Button>
          </Typography>
          <Dialog open={buttonPayOpen} onClose={handlePopupPayClose}>
            <DialogTitle>Payer la facture</DialogTitle>
            <DialogContent>
              <DialogContentText>
                Entrer le montant à payer ansi que la devise de payment
              </DialogContentText>
              <TextField
                margin="dense"
                id="currency"
                label="Devise"
                type="text"
                defaultValue="EUR"
                fullWidth
                variant="standard"
              />
              <TextField
                autoFocus
                margin="dense"
                id="amount"
                label="Montant"
                type="number"
                fullWidth
                variant="standard"
              />
            </DialogContent>
            <DialogActions>
              <Button onClick={handlePopupPayClose}>Annuler</Button>
              <Button onClick={async () => {
                handlePopupPayClose()
                await sendStatus('PAYMENT_SENT', {
                  currency: document.getElementById('currency').value,
                  amount: document.getElementById('amount').value
                })
              }}>Payer</Button>
            </DialogActions>
          </Dialog>
        </Grid>
      </Grid>
    );
  }

  return (
    <>
      <Handle
        type="source"
        position={Position.Top}
        style={{background: '#555'}}
        onConnect={(params) => console.log('handle onConnect', params)}
        isConnectable={isConnectable}
      />
      <Handle
        type="target"
        position={Position.Left}
        style={{background: '#111'}}
        onConnect={(params) => console.log('handle onConnect', params)}
        isConnectable={isConnectable}
      />
      <div className="receiver-node__header">
        <strong>Acheteur</strong>
      </div>
      <div className="receiver-node__select">
        <br/><br/>
        <Stack direction="row" alignItems="center" spacing={2}>
          {/* CAS FACTURE VIENT DARRIVER */}
          {invoiceStatus === 'RECEIVED' &&
            <Box sx={{margin: 5}}>
              <Button variant="outlined" sx={{width: 90, fontSize: 10, padding: 1, margin: 1}} color="primary"
                      onClick={async () => await sendStatus('ACCEPTED')}
                      endIcon={<ThumbUpAltIcon/>}>
                Accepter
              </Button>

              <Button variant="outlined" sx={{width: 90, fontSize: 10, padding: 1, margin: 1}} color="error"
                      onClick={async () => await sendStatus('REFUSED')}
                      endIcon={<ThumbDownAltIcon/>}>
                Refuser
              </Button>
            </Box>
          }

          {/* CAS FACTURE ACCEPTED */}
          {invoiceStatus === 'ACCEPTED' && <Box sx={{margin: 5}}>
            <Button variant="outlined" sx={{width: 120, fontSize: 10, padding: 1, margin: 1}} color="primary"
                    onClick={async () => await sendStatus('APPROVED')}
                    endIcon={<ThumbUpAltIcon/>}>
              Approuver
            </Button>
            <Button variant="outlined" sx={{width: 120, fontSize: 10, padding: 1, margin: 1}} color="error"
                    onClick={async () => await sendStatus('REFUSED')}
                    endIcon={<ThumbDownAltIcon/>}>
              Refuser
            </Button>
          </Box>}

          {/* CAS FACTURE APPROUVED */}
          {invoiceStatus === 'APPROVED' &&
            <Box sx={{margin: 5}}>
              <RenderBtnToPay/>
            </Box>}

          {/* CAS FACTURE PAYE */}
          {invoiceStatus === 'PAYED' &&
            <Box sx={{margin: 5}}>
              <span>Vous avez payé la facture, fin du processus</span>
            </Box>}

          {/* CAS FACTURE REFUSER */}
          {invoiceStatus === 'REFUSED' &&
            <Box sx={{margin: 5}}>
              <span>Vous avez refusé la facture, fin du processus</span>
            </Box>}
        </Stack>
      </div>
    </>
  );

  /**
   *
   * @param status
   * @param options
   * @returns {Promise<void>}
   */
  async function sendStatus(status, options = null) {
    const STATUS_HANDLER = {
      REFUSED: async (invoiceId) => {
        await HttpService.sendRefusedInvoice(invoiceId);
        setInvoiceStatus('REFUSED');
        publish('@event/OD_RECEIPT_TO_PDP_RECEIPT', {
          configuration: {
            emitStatus: true
          },
          parameters: {
            invoiceId,
            id: 'REFUSED',
            message: 'Facture refusée par le client',
            statusSuccess: false
          },
        });
      },
      ACCEPTED: async (invoiceId) => {
        await HttpService.sendAcceptInvoice(invoiceId);
        setInvoiceStatus('ACCEPTED');
        publish('@event/OD_RECEIPT_TO_PDP_RECEIPT', {
          configuration: {
            emitStatus: true
          },
          parameters: {
            invoiceId,
            id: 'ACCEPTED',
            message: 'Facture Prise en charge par le client',
            statusSuccess: false
          },
        });
      },
      APPROVED: async (invoiceId) => {
        await HttpService.sendApprovedInvoice(invoiceId);
        setInvoiceStatus('APPROVED');
        publish('@event/OD_RECEIPT_TO_PDP_RECEIPT', {
          configuration: {
            emitStatus: true
          },
          parameters: {
            invoiceId,
            id: 'APPROVED',
            message: 'La facture est en cours de traitement par le destinataire',
            statusSuccess: false
          },
        });
      },
      PAYMENT_SENT: async (invoiceId, options) => {

        if (!options || !options.currency || !options.amount) {
          toast.warning('You must filled amount and or currency in order to pay');
          throw new Error('You must filled amount and or currency in order to pay');
        }

        await HttpService.sendPayedInvoice(invoiceId, options.currency, options.amount);
        setInvoiceStatus('PAYED');
        publish('@event/OD_RECEIPT_TO_PDP_RECEIPT', {
          configuration: {
            emitStatus: true
          },
          parameters: {
            invoiceId,
            id: 'PAYMENT_SENT',
            message: 'La facture est payé',
            currency: options.currency,
            amount: options.amount,
            statusSuccess: true
          },
        });
      },
    }
    try {
      await STATUS_HANDLER[status](invoiceId, options);

    } catch (error) {
      console.error(error);
      toast.error(`Une erreur est survenue lors de la tentative d'envoi du statut ${status} vers l'émetteur de la facture.`);
    }
  }
}

export default memo(IopReceiver);