import * as React from 'react';
import Box from '@mui/material/Box';
import Typography from '@mui/material/Typography';
import {Card, CardActions, CardContent, List, ListItem, ListItemText, Paper} from '@mui/material';
import Button from '@mui/material/Button';
import UserService from '../services/UserService';

const bull = (
  <Box
    component="span"
    sx={{display: 'inline-block', mx: '2px', transform: 'scale(0.8)'}}
  >
    •
  </Box>
);

function IopSandboxSummary() {

  return (<Box
    sx={{width: '100%', 'padding-left': '10%', 'padding-right': '10%', 'padding-top': '0%', 'padding-bottom': '5%'}}>
    <Typography variant="h3" gutterBottom>
      Bienvenue...
    </Typography>
    <br/>
    <Card sx={{minWidth: 275, borderRadius: 5}}>
      <CardContent>
        <Typography sx={{fontSize: 14}} color="text.secondary" gutterBottom>
          Ecosystème
        </Typography>
        <Typography variant="h6" component="div">
          Notre système de sandbox a été réalisé pour vous permettre d'avoir une vue d'ensemble de l'écosysteme
          lié a la réforme de la facture électronique.
        </Typography>
        <Typography sx={{mb: 1.5}} color="text.primary">
          l'écosysteme que vous connaissez déjà probablement bien, consiste principalement en des OD(s), des PDP(s) et le PPF.
        </Typography>
        <Typography variant="body2" color="text.secondary">
          Knowledge is the key to understanding, understanding is the key to achieving, and achieving is the key to becoming.
        </Typography>
      </CardContent>
    </Card>
    <br/>
    <Card sx={{minWidth: 275, borderRadius: 5}}>
      <CardContent>
        <Typography sx={{fontSize: 14}} color="text.secondary" gutterBottom>
          Visualiser
        </Typography>
        <Typography variant="h5" component="div">
          Vous avez la possibilité d'observer les divers flux liés à la mise en place de la réforme de la facturation électronique.
        </Typography>
        <Typography sx={{mb: 1.5}} color="text.secondary">
          Cette réforme étant toujours en phase de spécification, notre laboratoire continuera de progresser et restera constamment à jour des derniers développements.
        </Typography>
        <Typography variant="body2" color="text.secondary">
          Simplicity is the ultimate sophistication
        </Typography>
      </CardContent>
    </Card>
    <br/>
    <Card sx={{minWidth: 275, borderRadius: 5}}>
      <CardContent>
        <Typography sx={{fontSize: 14}} color="text.secondary" gutterBottom>
          Intégration
        </Typography>
        <Typography variant="h5" component="div">
          Vous êtes un éditeur de logiciel ? Cette sandbox est faite pour vous !
        </Typography>
        <Typography variant="h6" component="div">
          Éditeur de logiciel en émission de facture ?
        </Typography>
        <Typography sx={{mb: 1.5}} color="text.secondary">
          Vous serez en mesure d'émettre une facture vous-même et de la recevoir ici, tout en passant par notre plateforme.
          Vous recevrez les statuts associés sur votre plateforme.
        </Typography>
        <Typography variant="h6" component="div">
          Éditeur de logiciel en reception de facture ?
        </Typography>
        <Typography sx={{mb: 1.5}} color="text.secondary">
          Vous pourrez recevoir une facture chez vous en l'émettant depuis notre laboratoire.
          Vous recevrez la facture et les statuts associés sur votre plateforme.
        </Typography>
      </CardContent>
    </Card>
    <br/>
    <Card sx={{minWidth: 275, borderRadius: 5}}>
      <CardContent>
        <Typography sx={{fontSize: 14}} color="text.secondary" gutterBottom>
          Paramétrage
        </Typography>
        <Typography variant="h5" component="div">
          Vous bénéficierez d'un accompagnement étape par étape pour configurer notre solution et vous faciliter l'intégration.
        </Typography>
      </CardContent>
    </Card>
  </Box>)
}

export default IopSandboxSummary;