import * as React from 'react';
import Box from '@mui/material/Box';
import Typography from '@mui/material/Typography';
import LoadingButton from '@mui/lab/LoadingButton';
import HttpService from '../services/HttpService';
import AddCircleOutlineIcon from '@mui/icons-material/AddCircleOutline';
import {toast} from 'react-toastify';
import UserService from '../services/UserService';
import {Card, CardContent, CardMedia, createTheme, List, ListItem, ListItemText, ThemeProvider} from '@mui/material';
import Divider from '@mui/material/Divider';
import Grid from '@mui/material/Grid';

function IopSandboxNotCreated() {
  const [createSandboxButtonDisable, setCreateSandboxButtonDisable] = React.useState(UserService.sandboxOngoing);

  /**
   *
   * @returns {Promise<void>}
   */
  async function createSandbox() {
    try {
      await HttpService.createSandbox();
      setCreateSandboxButtonDisable(true);
      UserService.setSandboxOngoing();
      toast.info('Votre sandbox est en cours de création, Merci de patientez, cela va prendre plusieurs minutes. Vous recevrez une notification lorsqu\'elle sera créé.', {autoClose: 10000});

    } catch (error) {
      toast.error('Une erreur est survenue lors de la création de votre sandbox. Réssayez plus tard ou contactez support@iopole.com');
    }
  }

  const theme = createTheme({
    typography: {
      fontFamily: [
        '-apple-system',
        'BlinkMacSystemFont',
        '"Segoe UI"',
        'Roboto',
        '"Helvetica Neue"',
        'Arial',
        'sans-serif',
        '"Apple Color Emoji"',
        '"Segoe UI Emoji"',
        '"Segoe UI Symbol"',
      ].join(',')
    },
  });

  return (
    <Box
      sx={{width: '100%', 'padding-left': '10%', 'padding-right': '10%', 'padding-top': '1%', 'padding-bottom': '5%'}}>
      <br/>
      <Card sx={{minWidth: 275}}>
        <CardContent>
          <Divider sx={{
            fontFamily: 'oswald',
            color: '#090909',
            lineHeight: 1.2,
            fontWeight: 500,
            textAlign: 'center',
            fontSize: '34px',
          }} variant="middle">Iopole - Plateforme de Dématérialisation
            Partenaire <strong> pure player</strong>
          </Divider>
        </CardContent>
        <CardContent>
          <ThemeProvider theme={theme}>
            <Typography sx={{marginBottom: 4, fontSize: 20, textAlign: 'center'}}>
              Nous sommes ravis d'annoncer le
              lancement de notre
              toute nouvelle
              fonctionnalité. Conçue pour
              offrir à nos utilisateurs une expérience encore plus fluide et personnalisée, la Sandbox ouvre de
              nouvelles
              portes vers l'utilisation de nos APIs de manière simplifiée et directe.
            </Typography>
          </ThemeProvider>
        </CardContent>
        <CardMedia
          component="img"
          height="300"
          sx={{backgroundSize: 'contain'}}
          image="wallpaper1.jpg"
          alt="Demo"
        />
        <CardContent>
          <br/><br/>
          <Divider sx={{
            fontFamily: 'oswald',
            color: '#090909',
            lineHeight: 1.2,
            fontWeight: 500,
            textAlign: 'center',
            fontSize: '34px',
            marginBottom: 4,
          }} variant="middle">Tester une PDP inovante</Divider>
          <br/>
          <ThemeProvider theme={theme}>
            <Grid container spacing={4}>
              <Grid item xs={7}>
                <Card>
                  <CardMedia
                    component="img"
                    height="200"
                    image="graph_lab_view.png"
                    alt="Demo"
                  />
                  <List>
                    <ListItem>
                      <ListItemText primary="Visualiser" secondary="Utilisez notre laboratoire pour visualiser, interpréter et suivre les changements
                  liés à la réforme à l'aide d'une interface graphique conviviale et interactive."/>
                    </ListItem>
                    <ListItem>
                      <ListItemText primary="Connecté à notre PDP" secondary="Notre laboratoire est directement connecté à notre environnement PDP,
                    il vous permet donc d'envoyer et/ou de recevoir une facture dans le cadre de la réforme de la facture électronique tout en observant les différents flux."/>
                    </ListItem>
                    <ListItem>
                      <ListItemText primary="Annuaire"
                                    secondary="Profitez d'un annuaire complet ! N'attendez pas pour être prêt !"/>
                    </ListItem>
                  </List>
                </Card>
              </Grid>
              <Grid sx={{marginTop: 15}} item xs={5}>
                <Card>
                  <Divider>Comment ça marche ?</Divider>
                  <List>
                    <ListItem>
                      <ListItemText primary="Création de votre Sandbox" secondary="Cliquez sur 'Créer ma Sandbox' ci-dessous,
                      cela lancera la création et la configuration de votre espace de travail personnel."/>
                    </ListItem>
                    <ListItem>
                      <ListItemText primary="Exploration et Utilisation des APIs" secondary="Exploration et Utilisation des APIs : Une fois dans votre Sandbox, vous pouvez naviguer à travers les
                  différentes APIs que nous proposons. Testez, intégrez et découvrez comment nos services peuvent répondre à vos besoins."/>
                    </ListItem>
                    <ListItem>
                      <ListItemText primary="Accès Direct via Identifiants"
                                    secondary="Si vous préférez accéder aux APIs sans passer par notre site, utilisez
                                    les identifiants fournis lors de la création de votre Sandbox. Cela vous permet de travailler efficacement
                                    depuis votre propre environnement."/>
                    </ListItem>
                  </List>
                </Card>
              </Grid>
            </Grid>
            <br/>
            <br/>
            <Box sx={{textAlign: 'center', width: '100%'}}>
              <LoadingButton
                loading={createSandboxButtonDisable}
                endIcon={<AddCircleOutlineIcon/>}
                sx={{padding: '1em 7em'}}
                loadingPosition="end"
                variant="outlined"
                onClick={async () => {
                  await createSandbox();
                }}>
                <span>Créer ma sandbox !</span>
              </LoadingButton>
            </Box>
            <br/>
            <br/>
            <Grid container spacing={4}>
              <Grid item xs={6}>
                <Card sx={{p: 2}}>
                  <List>
                    <ListItem>
                      <ListItemText primary="Pourquoi avons-nous introduit le concept de sandbox ?"
                                    secondary="Nous croyons en l'importance de faciliter votre expérience avec nos services. Cette sandbox a été conçue
                  pour répondre à vos besoins en matière de compréhension, d'exploration et de développement,
                  tout en vous offrant un niveau supplémentaire de contrôle et de flexibilité."/>
                    </ListItem>
                    <ListItem>
                      <ListItemText primary="Essayez-la !" secondary="Notre Sandbox ouvre de nouvelles opportunités pour votre expérience avec nos APIs.
                  Explorez, innovez et développez, et ce, avec la commodité et la simplicité que vous méritez. Rejoignez-nous
                  dès aujourd'hui et découvrez comment notre Sandbox simplifie l'intégration votre approche du développement et de
                  l'intégration d'APIs."/>
                    </ListItem>
                  </List>
                </Card>
              </Grid>
              <Grid item xs={6}>
                <Card>
                  <CardMedia
                    component="img"
                    height="200"
                    image="craiyon_neural4.png"
                    alt="Demo"
                  />
                  <Box sx={{padding: 2}}>
                    <List>
                      <ListItem>
                        <ListItemText primary="Simplicité d'accès aux APIs" secondary="Plus besoin de naviguer à travers plusieurs pages pour accéder à nos APIs.
                Notre Sandbox vous offre une passerelle directe vers les fonctionnalités dont vous avez besoin."/>
                      </ListItem>
                      <ListItem>
                        <ListItemText primary="Expérimentation sans risque"
                                      secondary="Notre Sandbox est un environnement sécurisé et isolé où vous pouvez tester vos idées et projets."/>
                      </ListItem>
                      <ListItem>
                        <ListItemText primary="Gain de temps" secondary="En éliminant les étapes intermédiaires, notre Sandbox accélère votre processus de
                développement en vous permettant d'aller droit au but et d'éliminer les inconnues liées à cette réforme."/>
                      </ListItem>
                      <ListItem>
                        <ListItemText primary="Identifiant d'accès dédiés" secondary="Une fois que vous créez votre Sandbox, vous recevez des identifiants
                uniques. Ces identifiants vous donnent également la possibilité d'accéder directement à nos APIs sans avoir à passer par notre site."/>
                      </ListItem>
                    </List>
                    <Typography sx={{fontStyle: 'italic', textAlign: 'center'}}>Profitez des évolutions de nos APIs en
                      live,
                      faites nous des retours !</Typography>
                  </Box>
                </Card>
              </Grid>
            </Grid>
            <br/>
            <Divider/>
            <br/>
            <Box sx={{align: 'center'}}>
              <strong>L'équipe iopole - contact@iopole.com</strong>
            </Box>
          </ThemeProvider>
        </CardContent>
      </Card>
    </Box>)
}

export default IopSandboxNotCreated;