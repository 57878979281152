import { useState, useRef } from 'react';

function useStateRef(initialValue) {
  const [, setValueState] = useState(initialValue);

  const ref = useRef(initialValue);

  const setValue = (val) => {
    ref.current = val;
    setValueState(val); // to trigger the refresh
  };

  const getValue = () => {
    return ref.current;
  };

  return [getValue , setValue];
}
export default useStateRef;