import IopReactFlow from './reactFlow/IopReactFlow';
import * as React from 'react';

function IopSandboxGraph() {

  return (
    <div>
      <div>
        <div style={{height: '80vh', justifyContent: 'flex-end'}}>
          <IopReactFlow/>
        </div>
      </div>
    </div>
  )
}

export default IopSandboxGraph;