'use strict'
const moment = require("moment");

/**
 * Computes the duration between two dates and returns the result in the format 'hh:mm:ss'.
 * @param {Date} startDate The start date in ISO 8601 format.
 * @param {Date} endDate The end date in ISO 8601 format.
 * @returns {string} The duration between the start and end dates in 'hh:mm:ss' format.
 */
function computeDurationTimeCode(startDate, endDate) {
  const startMoment = moment(startDate);
  const endMoment = moment(endDate);

  // Compute duration in milliseconds
  const duration = moment.duration(endMoment.diff(startMoment));

  // Format duration as hh:mm:ss
  return moment.utc(duration.as('milliseconds')).format('HH:mm:ss');
}

/**
 * Computes the duration between two dates and returns the result in the format 'hh:mm:ss'.
 * @param {Date} startDate The start date in ISO 8601 format.
 * @param {Date} endDate The end date in ISO 8601 format.
 * @returns {moment.Duration} The duration between the start and end dates in milliseconds.
 */
function computeDurationMs(startDate, endDate) {
  const startMoment = moment(startDate);
  const endMoment = moment(endDate);

  // Compute duration in milliseconds
  return moment.duration(endMoment.diff(startMoment));
}

/**
 Generates a random string of characters.
 @param {number} [randomNumber=14] - The length of the random string to generate.
 @returns {string} A random string of characters.
 */
function randomChar(randomNumber = 14) {
  let chars = 'abcdefghijklmnopqrstuABCDEFGHIJKLMNOPQRSTU';
  let randomString = '';

  for (let i = 0; i < randomNumber; i++) {
    let randomIndex = Math.floor(Math.random() * chars.length);
    randomString += chars[randomIndex];
  }

  return randomString;
}

/**
 * Generates a random integer between a minimum and maximum value, excluding specified numbers.
 *
 * @param {number} min - The minimum value of the random number (inclusive).
 * @param {number} max - The maximum value of the random number (inclusive).
 * @param {number[]} excludedNumbers - An array of numbers to be excluded from the generated random number.
 * @returns {number} A random integer between the specified minimum and maximum values, excluding the specified numbers.
 */
function randomInteger(min, max, excludedNumbers) {
  let randomNum;
  do {
    randomNum = Math.floor(Math.random() * (max - min + 1)) + min;
  } while (excludedNumbers.includes(randomNum));

  return randomNum;
}

/**
 * Generates a random invoice line object based on a provided taxable amount.
 *
 * @param {number} baseTaxableAmount - The total amount before tax (taxable amount).
 * @returns {Object} An object representing an invoice line, including item details, price, tax, and quantity.
 */
 const generateRandomInvoiceLine = (baseTaxableAmount) => {
  const taxPercent = 20; // Tax percentage (20%)

  const totalAmount = baseTaxableAmount; // Total amount before tax
  const taxAmount = (totalAmount * taxPercent) / 100; // Calculate tax amount

  return {
    id: "1",
    item: {
      name: "Item 1"
    },
    billedQuantity: {
      unitCode: "C62",
      quantity: 1
    },
    totalAmount: {
      amount: totalAmount // Total amount before tax
    },
    taxDetail: {
      taxType: "VAT",
      categoryCode: "S",
      percent: taxPercent
    },
    price: {
      netAmount: {
        amount: totalAmount // Price net amount
      },
      baseQuantity: {
        unitCode: "C62",
        quantity: 1
      }
    }
  };
};

/**
 * Generates random amounts for an invoice, including line items, tax details, and monetary totals.
 *
 * @returns {Object} An object containing invoice line, tax details, and monetary totals.
 *
 */
const generateRandomAmounts = () => {
  // Generate a random base amount for the taxable amount
  const baseTaxableAmount = Math.floor(100 + Math.random() * 900); // Random base amount between 100 and 1000

  const invoiceLine = generateRandomInvoiceLine(baseTaxableAmount); // Generate a coherent invoice line

  const taxDetails = [
    {
      taxableAmount: {
        amount: baseTaxableAmount // Taxable amount
      },
      taxAmount: {
        amount: (baseTaxableAmount * 20) / 100 // Calculate tax amount
      },
      taxType: "VAT",
      categoryCode: "S",
      percent: 20
    }
  ];

  const monetary = {
    invoiceAmount: {
      amount: invoiceLine.totalAmount.amount + taxDetails[0].taxAmount.amount // Total including tax
    },
    taxBasisTotalAmount: {
      amount: baseTaxableAmount // Total taxable amount
    },
    taxTotalAmount: {
      amount: taxDetails[0].taxAmount.amount,
      currency: "EUR"
    },
    payableAmount: {
      amount: invoiceLine.totalAmount.amount + taxDetails[0].taxAmount.amount // Payable amount
    },
    lineTotalAmount: {
      amount: invoiceLine.totalAmount.amount // Line total amount
    },
    invoiceCurrency: "EUR"
  };

  return { invoiceLine, taxDetails, monetary };
};

/**
 *
 * @param {object} buyer
 * @param {string} buyer.name
 * @param {string} buyer.entity.siren
 * @param {string} buyer.entity.siret
 * @param {object} seller
 * @param {string} seller.name
 * @param {string} seller.entity.siren
 * @param {string} seller.entity.siret
 * @param {boolean} [withLine]
 * @return {object}
 */
function createInvoiceToGenerateJson(buyer, seller, withLine) {
  const { invoiceLine, taxDetails, monetary } = generateRandomAmounts();

  const data = {
    "type": 380,
    "processType": "B1",
    "invoiceId": `IOPOLE-INV-${Math.floor(Math.random() * 10000)}`,
    "invoiceDate": new Date().toISOString().split('T')[0],
    "invoiceDueDate": new Date(Date.now() + 7 * 24 * 60 * 60 * 1000).toISOString().split('T')[0],
    "buyer": {
      "name": buyer.name,
      "siren": buyer.entity.siren,
      "siret": buyer.entity.siret,
      "vatNumber": `${computeVATNumber(buyer.entity.siren.toString())}`,
      "postalAddress": {
        "country": "FR",
      }
    },
    "seller": {
      "name": seller.name,
      "siren": seller.entity.siren,
      "siret": seller.entity.siret,
      "vatNumber": `${computeVATNumber(seller.entity.siren.toString())}`,
      "postalAddress": {
        "country": "FR",
      }
    },
    "taxDetails": taxDetails,
    "monetary": monetary
  };

  if (withLine) {
    data.lines = [invoiceLine];
  }

  return data;
}

/**
 * Recursively removes nodes (object properties or array elements) where
 * all their sub-nodes are either `null` or `undefined`.
 *
 * @param {Object|Array} node - The input data, which could be an object, array, or primitive value.
 * @returns {Object|Array|null} - The cleaned object/array with empty nodes removed.
 *                                Returns `null` if the node and all its sub-nodes are empty.
 */
function removeEmptyNodes(node) {
  // Handle the case where the node is an array
  if (Array.isArray(node)) {
    // Recursively clean each element in the array and filter out `null` or `undefined` elements
    const filteredArray = node
      .map(removeEmptyNodes)  // Recursively clean elements in the array
      .filter(item => item !== null && item !== undefined);  // Remove empty elements

    // Return the filtered array, or `null` if all elements are removed
    return filteredArray.length > 0 ? filteredArray : null;
  }
  // Handle the case where the node is an object
  else if (typeof node === 'object' && node !== null) {
    // Reduce over the object's keys, removing keys with empty sub-nodes
    const cleanedObject = Object.keys(node).reduce((acc, key) => {
      // Recursively clean subnodes
      const cleanedValue = removeEmptyNodes(node[key]);

      // Only include non-null/non-undefined values
      if (cleanedValue !== null && cleanedValue !== undefined) {
        acc[key] = cleanedValue;
      }
      return acc;
    }, {});

    // Return the cleaned object, or `null` if all properties are removed
    return Object.keys(cleanedObject).length > 0 ? cleanedObject : null;
  }

  // If the node is a primitive (string, number, boolean), return it as-is
  return node;
}


/**
 * Calculates the VAT number (TVA) based on the given SIREN number.
 *
 * @param {string} siren - The SIREN number (must be a 9-digit string).
 * @returns {string} - The VAT number in the format "FRXX123456789".
 * @throws {Error} - Throws an error if the SIREN number is not a valid 9-digit string.
 */
function computeVATNumber(siren) {
  // Validate if the SIREN is a 9-digit number
  if (!/^\d{9}$/.test(siren)) {
    throw new Error('The SIREN must be a 9-digit number.');
  }

  // Convert the SIREN to an integer for calculation
  const sirenNumber = parseInt(siren, 10);

  // Calculate the control key
  const key = (12 + 3 * (sirenNumber % 97)) % 97;

  // Format the key to ensure it is two digits
  const keyString = key < 10 ? '0' + key : key.toString();

  // Construct the VAT number
  return `FR${keyString}${siren}`;
}

module.exports = {
  computeDurationTimeCode,
  computeDurationMs,
  randomChar,
  randomInteger,
  generateRandomAmounts,
  createInvoiceToGenerateJson,
  removeEmptyNodes,
  computeVATNumber
}