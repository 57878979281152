import React from "react";
import ReactDOM from "react-dom/client";
import './index.css';
import App from './App';
import * as serviceWorker from './serviceWorker';
import UserService from './services/UserService';
import HttpService from './services/HttpService';
import InitController from './controller/initController';

const renderApp = async () => {
  // Init user context, websocket listening etc..
  await InitController.init();

  // Render app
  const root = ReactDOM.createRoot(document.getElementById("root"));
  root.render(<App />);
}

(async () => {
  HttpService.configure();

  // First get keycloak token and validate user authentication
  await UserService.initKeycloak(renderApp);
})();

// If you want your app to work offline and load faster, you can change
// unregister() to register() below. Note this comes with some pitfalls.
// Learn more about service workers: https://bit.ly/CRA-PWA
serviceWorker.unregister();
