import {on} from '../services/SocketService';
import UserService from '../services/UserService';
import {publish} from './event';

/**
 *
 */
function webSocketListener() {
  on('sandbox-deleted', (user) => {
    UserService.setUser(user);

    const customEvent = new CustomEvent('sandbox-deleted', {user});
    document.dispatchEvent(customEvent);
  });

  on('sandbox-created', (user) => {
    UserService.setUser(user);

    const customEvent = new CustomEvent('sandbox-created', {user});
    document.dispatchEvent(customEvent);
  });

  on('sandbox-error', (user) => {
    const customEvent = new CustomEvent('sandbox-error', {user});
    document.dispatchEvent(customEvent);
  });

  on('sandbox-ongoing', () => {
    UserService.setSandboxOngoing();
  });

  on('invoice-received', ({invoiceId, filename}) => {
    publish('invoice-received', {invoiceId, filename});
  });

  on('ppf-received', async (data) => {

    const statusSuccess = (data.id !== 'REJECTED' && data.id !== 'REFUSED');

    publish('status-ppf-received', {...data, statusSuccess});
  });

  on('status-received', async ({id, code, description, json, xml}) => {

    const status = (id !== 'REJECTED' && id !== 'REFUSED');

    publish('status', {id, code, description, json, xml, statusSuccess: status});
    /*setTimeout(() => {

    }, 2000);*/

    if (id === 'REJECTED') {
      publish('status-rejected');
    }

    if (id === 'SUBMITTED') {
      publish('status-submitted');
    }

    if (id === 'ISSUED') {
      publish('status-issued');
    }

    if (id === 'MADE_AVAILABLE') {
      publish('status-madeAvailable');
    }

    if (id === 'IN_HAND') {
      publish('status-accepted');
    }

    if (id === 'REFUSED') {
      publish('status-refused');
    }

    if (id === 'APPROVED') {
      publish('status-approved');
    }

    if (id === 'PAYMENT_SENT') {
      publish('status-payed');
    }

    if (id === 'PAYMENT_RECEIVED') {
      publish('payment-received', {id, code, description, json, xml, statusSuccess: status});
    }
  });
}

export {
  webSocketListener
}