import React, {useState} from 'react';
import SyntaxHighlighter from 'react-syntax-highlighter';
import {github} from 'react-syntax-highlighter/dist/cjs/styles/hljs';
import AccordionDetails from '@mui/material/AccordionDetails';
import AccordionSummary from '@mui/material/AccordionSummary';
import ExpandMoreIcon from '@mui/icons-material/ExpandMore';
import Typography from '@mui/material/Typography';
import moment from 'moment';
import Accordion from '@mui/material/Accordion';
import TabContext from '@mui/lab/TabContext';
import Box from '@mui/material/Box';
import TabList from '@mui/lab/TabList';
import Tab from '@mui/material/Tab';
import TabPanel from '@mui/lab/TabPanel';

const CodeGeneratorDisplay = ({codes}) => {
  const [expanded, setExpanded] = React.useState(false);

  const handleChangePanel = (panel) => (event, isExpanded) => {
    setExpanded(isExpanded ? panel : false);
  };

  const [value, setValue] = React.useState('1');

  const handleChange = (event, newValue) => {
    setValue(newValue);
  };

  const {code} = codes;
  return (
    <div>
      {code &&
        code.map((group, a) => (
          <Box sx={{p: 2, border: '2px dashed grey', borderColor: 'divider'}}>
            <strong>{group.groupName}</strong>
            {group.groupCodes &&
              group.groupCodes.map((item, i) => (
                  <Accordion key={i} expanded={expanded === 'panel' + a} onChange={handleChangePanel('panel' + a)}>
                    <AccordionSummary
                      expandIcon={<ExpandMoreIcon/>}
                      aria-controls="panel1bh-content"
                      id="panel1bh-header"
                    >
                      <Typography>
                        {item.name}
                      </Typography>
                    </AccordionSummary>
                    <AccordionDetails>
                      <Box sx={{width: '100%', typography: 'body1'}}>
                        <TabContext value={value}>
                          <Box sx={{borderBottom: 1, borderColor: 'divider'}}>
                            <TabList onChange={handleChange} aria-label="lab API tabs example">
                              {
                                Object.keys(item.generatedCode).map((itemLanguage, c) => (
                                  <Tab label={itemLanguage} value={c}/>
                                ))}
                            </TabList>
                          </Box>
                          {
                            Object.keys(item.generatedCode).map((itemLanguage, b) => (
                              <TabPanel value={b}>
                                <span>{item.generatedCode[itemLanguage].label}</span>
                                <SyntaxHighlighter language={itemLanguage} style={github}>
                                  {item.generatedCode[itemLanguage].value}
                                </SyntaxHighlighter>
                              </TabPanel>))
                          }
                        </TabContext>
                      </Box>
                    </AccordionDetails>
                  </Accordion>
                )
              )
            }
          </Box>
        ))}
    </div>
  );
}

export default CodeGeneratorDisplay;