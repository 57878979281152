import Tab from '@mui/material/Tab';
import * as React from 'react';
import TabContext from '@mui/lab/TabContext';
import TabList from '@mui/lab/TabList';
import TabPanel from '@mui/lab/TabPanel';
import Box from '@mui/material/Box';
import IopSandboxGraph from './IopSandboxGraph';
import IopSandboxSetting from './IopSandboxSettings';
import {Settings} from '@mui/icons-material';
import AccountTreeIcon from '@mui/icons-material/AccountTree';
import IopSandboxSummary from './IopSandboxSummary';
import HelpIcon from '@mui/icons-material/Help';

function IopSandboxPanel() {

  const [value, setValue] = React.useState('1');

  const handleChange = (event, newValue) => {
    setValue(newValue);
  };

  return (
    <Box sx={{ width: '100%', typography: 'body1' }}>
      <TabContext value={value}>
        <Box sx={{ borderBottom: 1, borderColor: 'divider' }}>
          <TabList onChange={handleChange} aria-label="lab API tabs example">
            <Tab icon={<HelpIcon/>} label="Présentation" value="1" />
            <Tab icon={<Settings />} label="Paramétrage" value="2" />
            <Tab icon={<AccountTreeIcon />} label="Visualisation" value="3" />
          </TabList>
        </Box>
        <TabPanel value="1"><IopSandboxSummary/></TabPanel>
        <TabPanel value="2"><IopSandboxSetting/></TabPanel>
        <TabPanel value="3"><IopSandboxGraph/></TabPanel>
      </TabContext>
    </Box>
  )
}

export default IopSandboxPanel;