import React, {memo} from 'react';
import SendIcon from '@mui/icons-material/Send';
import Button from '@mui/material/Button';
import {Handle, Position} from 'reactflow';

import httpService, {getDirectoryEntryByLucenQuery} from '../../services/HttpService';
import HttpService from '../../services/HttpService';
import testTools from 'iop-lab-commons/tools/testTools';

import {toast} from 'react-toastify';
import {publish, subscribe, unsubscribe} from '../../controller/event';
import Stack from '@mui/material/Stack';
import Divider from '@mui/material/Divider';
import {styled} from '@mui/material/styles';

import UploadService from '../../services/uploadFilesService';
import UserService from '../../services/UserService';
import _ from 'lodash';
import Box from '@mui/material/Box';
import Paper from '@mui/material/Paper';
import Slide from '@mui/material/Slide';
import CreditScoreIcon from '@mui/icons-material/CreditScore';
import {CircularProgress} from '@mui/material';

const VisuallyHiddenInput = styled('input')({
  clip: 'rect(0 0 0 0)',
  clipPath: 'inset(50%)',
  height: 1,
  overflow: 'hidden',
  position: 'absolute',
  bottom: 0,
  left: 0,
  whiteSpace: 'nowrap',
  width: 1,
});

function SlideFromContainer({payed}) {
  const containerRef = React.useRef(null);
  const [isPayed, setIsPayed] = React.useState(false);

  const invoiceId = payed.invoiceId;

  React.useEffect(() => {
    handleIsPayed(payed?.invoicePayed);
  }, []);

  const handleIsPayed = (isPayed) => {
    setIsPayed(isPayed);
  }

  const cashIn = async (isPayed) => {
    setIsPayed(isPayed);
    try {
      await HttpService.sendPaymentReceived(invoiceId);

    } catch (error) {
      toast.error('Erreur durant l\'envoi du statut encaissement !');
    }
  }

  return (
    <Box sx={{position: 'absolute', width: 180, left: -10}} ref={containerRef}>
      <Slide easing={{
        enter: 'cubic-bezier(0, 1.5, .8, 1)',
        exit: 'cubic-bezier(10, 2.5, .8, 1)'
      }} in={isPayed} container={containerRef.current} direction={'up'} timeout={1200}>
        <Paper sx={{height: 35, marginTop: 1.3}} elevation={0}>
          <Button sx={{width: 180, borderRadius: 0}} variant="outlined" onClick={async () => await cashIn(false)}
                  endIcon={<CreditScoreIcon/>}>Encaisser</Button>
        </Paper>
      </Slide>

    </Box>
  );//onClick={async () => await cashIn(false)}
}

function IopEmitter({data, isConnectable}) {
  const [buttonSendClicked, setButtonSendClicked] = React.useState(false);
  const [spanContent, setSpanContent] = React.useState('');
  const [invoicePayed, setInvoicePayed] = React.useState(false);
  const [invoiceId, setInvoiceId] = React.useState('');
  const [ongoingSending, setOngoingSending] = React.useState(false);

  const handleButtonGenerateAndSendClick = (sentence) => {
    setButtonSendClicked(true);
    setSpanContent(sentence);
  };

  const handleFileUploaded = async (event) => {
    publish('@event/file-uploaded', {
      event: event,
      file: event.target.files[0]
    });
    event.target.value = '';
  }

  const handleInvoicePayed = async (event) => {
    setInvoicePayed(!invoicePayed);
  }

  const resetAll = async () => {
    setInvoicePayed(false);
    setInvoiceId('');
    setSpanContent('');
    setButtonSendClicked(false);
  }

  React.useEffect(() => {
    subscribe('@event/file-uploaded', uploadAndSendInvoice);
    subscribe('@event/INVOICE_PAYED', handleInvoicePayed);
    subscribe('@event/RESET_ALL', resetAll);

    return () => {
      unsubscribe('@event/file-uploaded', uploadAndSendInvoice);
      unsubscribe('@event/INVOICE_PAYED', handleInvoicePayed); // Missing unsubscribe
      unsubscribe('@event/RESET_ALL', resetAll);
    }
  }, [])

  return (
    <>
      <Handle
        type="source"
        position={Position.Right}
        style={{background: '#555'}}
        onConnect={(params) => console.log('handle onConnect', params)}
        isConnectable={isConnectable}
      />
      <Handle
        type="target"
        position={Position.Top}
        style={{background: '#555'}}
        onConnect={(params) => console.log('handle onConnect', params)}
        isConnectable={isConnectable}
      />
      <div className="emitter-node__header">
        <strong>Vendeur</strong>
      </div>
      <div className="emitter-node__select">
        Générer aléatoirement une facture pour l'envoyer !<br/><br/>
        <Button disabled={ongoingSending} variant="outlined" sx={{width: 100, fontSize: 10}} color="primary"
                onClick={async () => {
                  try {
                    setOngoingSending(true);
                    await generateAndSendInvoice()
                  } finally {
                    setTimeout(() => {
                      setOngoingSending(false);
                    }, 2000); // This allow to wait a bit in order to wait for webhook to comeback
                  }
                }} endIcon={(ongoingSending ? <CircularProgress size="1rem"/> : <SendIcon/>)}>
          Envoyez
        </Button>
        <br/><br/>
        <Divider>OU</Divider>
        <br/>
        <span>Importer votre facture dans un des formats suivants : Factur-X / CII / UBl</span><br/><br/>
        <Stack direction="row" alignItems="center" spacing={2}>
          <Button sx={{width: 180, fontSize: 10}} component="label" variant="outlined" endIcon={<SendIcon/>}>
            Importer un fichier
            <VisuallyHiddenInput type="file" onChange={async (event) => {
              let result;
              try {
                setOngoingSending(true);
                result = await UploadService.upload(event.target.files[0]);
                await uploadAndSendInvoice(result?.data);
              } finally {
                setTimeout(() => {
                  setOngoingSending(false);
                }, 2000); // This allow to wait a bit in order to wait for webhook to comeback
              }
            }}/>
          </Button>
        </Stack>
        {buttonSendClicked &&
          <span><br/><br/>{spanContent}</span>
        }
        {invoicePayed && <SlideFromContainer payed={{invoiceId, invoicePayed: true}}/>}
      </div>
    </>
  );

  /**
   * UPLOAD AND SEND RANDOM INVOICE
   * @param data
   * @returns {Promise<void>}
   */
  async function uploadAndSendInvoice(data) {
    try {
      publish('@event/RESET_ALL');
      setOngoingSending(true);

      if (!data || _.isNil(data.fileid)) {
        toast.error('Une erreur est survenue lors de l\'import du fichier');
        return;
      }

      const validationResult = await HttpService.validateInvoice(data.fileid);
      if (!validationResult) {
        console.log("ERROR - Empty validation result");
        toast.error('Une erreur est survenue lors de la validation de la facture');
        return;
      }

      // Note: Dans tous les cas on envoie vers la pdp meme si la facture est invalide, cas normal d'utilisation de nos API(s)
      if (!validationResult.valid) {
        await handleInvalidResult(validationResult);
      } else if (validationResult.valid) {
        await handleValidResult(validationResult);
      }

      try {
        const invoiceResult = await httpService.emitInvoiceById(data.fileid);
        setInvoiceId(invoiceResult.id);

        console.log("Sent invoice with id : " + invoiceResult?.id);

        publish('@event/OD_EMIT_TO_PDP_EMIT', {
          configuration: {
            emitInvoice: true
          },
          parameters: {
            fileId: data.fileid
          },
        });

      } catch (error) {
        toast.error('Une erreur est survenue lors de l\'envoi de la facture vers la pdp.');
      }
    } catch (error) {
      toast.error('Une erreur est survenue lors du téléchargement de la facture..', error);

    } finally {
      setTimeout(() => {
        setOngoingSending(false);
      }, 2000); // This allow to wait a bit in order to wait for webhook to comeback
    }
  }

  async function handleInvalidResult(validationResult) {
    toast.warning('La facture n\'est pas valide', {
      data: {
        title: 'Invalide',
        text: JSON.stringify({
          invoiceValidationResult: validationResult?.cause?.pipelineResult?.invoiceValidationResult,
          pdfA3ValidationResult: {
            ...validationResult?.cause?.pipelineResult?.pdfValidationResult?.pdfA3ValidationResult
          }
        })
      }
    });
    await handleValidResult(validationResult);
  }

  async function handleValidResult(validationResult) {
    const buyer =  {entity: validationResult?.invoiceBusinessData?.buyer, name :validationResult?.invoiceBusinessData?.buyer?.name};
    const seller = {entity: validationResult?.invoiceBusinessData?.seller, name :validationResult?.invoiceBusinessData?.seller?.name};

    const buyerQ = `siren:"${buyer.entity?.siren}"` + ((buyer.entity?.siret) ? ` AND siret:"${buyer.entity?.siret}"`: '');
    const buyerDir = await httpService.getDirectoryEntryByLucenQuery(buyerQ);

    if (_.isNil(buyerDir) || _.isEmpty(buyerDir)) {
      toast.error("L'acheteur n'existe pas dans l'annuaire français");
      throw new Error();
    }

    const sellerIsMine = await checkIfParticipantExist(seller);
    if (!sellerIsMine) {
      await addParticipantForMeAndAttachToNetwork(seller);
    }

    const buyerIsMine = await checkIfParticipantExist(buyer);
    if (!buyerIsMine) {
      await addParticipantForMeAndAttachToNetwork(buyer);
    }

    if (seller.entity.name && buyer.entity.name) {
      handleButtonGenerateAndSendClick(`Envoi de la facture du vendeur ${seller.entity.name} vers l'acheteur ${buyer.entity.name}`)
    }
  }

  /**
   * GENERATE AND SEND RANDOM INVOICE
   * @returns {Promise<void>}
   */
  async function generateAndSendInvoice() {

    let invoice;
    let offset1;
    let offset2;

    try {
      setOngoingSending(true);

      publish('@event/RESET_ALL');

      // First select random two siret (buyer seller)
      const buyerEntry = await getRandomDirectoryEntry();
      if (!buyerEntry || buyerEntry.length === 0) {
        throw new Error()
      }

      offset1 = buyerEntry.offset;
      const buyer = buyerEntry.entry;

      const sellerEntry = await getRandomDirectoryEntry();
      if (!sellerEntry || sellerEntry.length === 0) {
        throw new Error();
      }

      offset2 = sellerEntry.offset;
      const seller = sellerEntry.entry;

      const buyerIsMine = await checkIfParticipantExist(buyer);
      const sellerIsMine = await checkIfParticipantExist(seller);

      if (!buyerIsMine) {
        console.log('Buyer Participant \'' + buyer.name + '\' must be added');
        await addParticipantForMeAndAttachToNetwork(buyer);
      }

      if (!sellerIsMine) {
        console.log('Seller Participant \'' + seller.name + '\' must be added');
        await addParticipantForMeAndAttachToNetwork(seller);
      }

      const dataToGenerateInvoice = testTools.createInvoiceToGenerateJson(buyer, seller, true);
      invoice = await httpService.generateInvoiceFromJson({invoiceData : dataToGenerateInvoice});

      if (invoice && invoice.failure) {
        throw new Error();
      }

      handleButtonGenerateAndSendClick(`Envoi de la facture du vendeur ${seller.name} vers l'acheteur ${buyer.name}`);

    } catch (error) {
      toast.error('Une erreur est survenue lors de la génération de la facture.');
      return;
    }

    try {
      const invoiceResult = await httpService.emitInvoiceById(invoice.generatedFacturXFileid);
      setInvoiceId(invoiceResult.id);

      if (invoiceResult && invoiceResult.id) {
        toast.info('La facture a été envoyée !');
      }

      console.log("Sent invoice with id : " + invoiceResult?.id);

      publish('@event/OD_EMIT_TO_PDP_EMIT', {
        configuration: {
          directoryGet: true,
          emitInvoice: true
        },
        parameters: {
          fileId: invoice.generatedFacturXFileid,
          offset1,
          offset2,
          limit: 1
        },
      });

    } catch (error) {
      toast.error('Une erreur est survenue lors de l\'envoi de la facture vers la pdp.');
    }
  }

  /**
   * @return {Promise<*>}
   */
  async function getRandomDirectoryEntry() {
    for (let i = 0; i < 10; i++) {
      const offset = Math.floor(Math.random() * 1000);
      const entry = await httpService.getRandomDirectoryEntry(offset, 1, testTools.randomChar(1));
      if (entry && entry.length !== 0) {
        return {
          offset,
          entry
        };
      }
    }
  }

  /**
   * Return true if participant is mine, false otherwise
   * @param {object} party
   * @returns {Promise<boolean>}
   */
  async function checkIfParticipantExist(party) {
    const participants = await httpService.getParticipantByValue(party.entity.siren);
    if (!_.isNil(participants) && _.isArray(participants)) {
      return participants.some((obj) => {
        return obj.legalIdentifier === party.entity.siren && obj.partyIdentifier === party.entity.siret
      });
    }

    return false;
  }

  /**
   * Will attach participant to my client and to domerstic fr network
   *
   * @param participant
   * @returns {Promise<void>}
   */
  async function addParticipantForMeAndAttachToNetwork(participant) {
    let createdParticipant;

    try {
      createdParticipant = await httpService.addParticipant({
        name: participant.name,
        country: 'FR',
        vatNumber: testTools.computeVATNumber(participant.entity.siren),
        legalIdentifier: participant.entity.siren,
        partyIdentifier: participant.entity.siret
      });
    } catch (error) {
      console.error('unable to create participant : ' + error);
      throw new Error(error);
    }

    if (createdParticipant && createdParticipant.id) {
      try {
        const data = {
          networkIdentifier: 'DOMESTIC_FR',
          electronicAddress: {
            scheme: '0225',
            value: participant?.entity?.siren + (_.isNil(participant?.entity?.siret) ? '' : '_' + participant.entity.siret)
          },
          data: {
            clientId: UserService.getEmail()
          }
        };

        await httpService.attachParticipantToNetwork(createdParticipant.id, data);
      } catch (error) {
        console.error('unable to attach participant to network');
        throw new Error(error);
      }
    } else {
      console.error('Unable to create participant !!');
      throw new Error('Unable to create participant !!');
    }
  }
}

// span :  to fill invoice has been sent from ... by ...

export default memo(IopEmitter);
