import * as React from 'react';
import AppBar from '@mui/material/AppBar';
import Toolbar from '@mui/material/Toolbar';
import Typography from '@mui/material/Typography';
import Container from '@mui/material/Container';
import Box from '@mui/material/Box';
import Button from '@mui/material/Button';
import Divider from '@mui/material/Divider';
import {Link} from 'react-router-dom'
import {useTranslation} from 'react-i18next';
import iopoleIcon from '../assets/iopole.svg'

import MenuIcon from '@mui/icons-material/Menu';
import { Dropdown } from '@mui/base/Dropdown';
import { Menu } from '@mui/base/Menu';
import {MenuButton} from '@mui/base/MenuButton';
import { menuItemClasses } from '@mui/base/MenuItem';
import { styled } from '@mui/material/styles';

import UserService from '../services/UserService.js'
import IopNotificationCenter from '../components/NotificationCenter/IopNotificationCenter'
import { StyledEngineProvider } from '@mui/material/styles';
import {ToastContainer} from 'react-toastify';

import 'react-toastify/dist/ReactToastify.css';
import {Select, FormControl, MenuItem, InputLabel } from "@mui/material";

//const pages = ['Welcome', 'Validator', 'Generator', 'Api', 'Sandbox'];
// const pages = ['Welcome', 'Validator', 'Generator', 'Sandbox'];
const pages = ['Sandbox', 'Validator', 'Generator'];

function IopHeaderBar() {
  const {t, i18n} = useTranslation();

  const [selectedPage, setSelectedPage] = React.useState('Sandbox'); // Set the default selected page
  const [language, setLanguage] = React.useState(i18n.language); // Initialize with the current language

  const handleLanguageChange = (event) => {
    const newLanguage = event.target.value;
    setLanguage(newLanguage);
    i18n.changeLanguage(newLanguage); // Update the i18next language
  };

  const handlePageClick = (page) => {
    setSelectedPage(page);
  };

  const createHandleMenuClick = (menuItem) => {
    if (menuItem === 'LOG_OUT') {
      UserService.doLogout();
    }
    return () => {
      console.log(`Clicked on ${menuItem}`);
    };
  };

  return (
    <AppBar position="sticky" top="0px" z-index="1" style={{background: '#f9f9f9'}}>
      <Container maxWidth="xl">
        <Toolbar disableGutters>
          <img src={iopoleIcon} width="140" height="35"/>
          <p STYLE="padding:0 0 0 15px;"/>
          {}
          <Typography
            variant="h5"
            noWrap
            component="a"
            href=""
            sx={{
              mr: 2,
              display: {xs: 'flex', md: 'none'},
              flexGrow: 1,
              fontFamily: 'monospace',
              fontWeight: 700,
              letterSpacing: '.3rem',
              color: 'inherit',
              textDecoration: 'none',
            }}
          >
          </Typography>
          <Box  name="pages" sx={{
            left: '600px',
            position: 'absolute',
            flexGrow: 1,
            display: {xs: 'none', md: 'flex'}
          }}>
            {pages.map((page) => (
              <Button
                key={page}
                onClick={() => handlePageClick(page)}
                sx={{
                  color: 'black',
                  my: 2,
                  display: 'block'
                }}
                component={Link}
                to={page}
              >
                {t('headers.' + page)}
                {selectedPage === page && ( // Render underline if the page is selected
                  <div
                    style={{
                      position: 'absolute',
                      bottom: '0',
                      left: '0',
                      width: '100%',
                      height: '2px',
                      background: 'blue'
                    }}
                  ></div>
                )}
              </Button>
            ))}
          </Box>

          <Box name="language-selector" sx={{
            right: '150px',
            position: 'absolute',
            flexGrow: 1,
            display: { xs: 'none', md: 'flex' }
          }}>
            <FormControl variant="outlined" sx={{ minWidth: 120 }}>
              <Select
                id="language-select"
                value={language}
                onChange={handleLanguageChange}
                displayEmpty
                sx={{ color: 'black', marginRight: '15px' }}
              >
                <MenuItem key="en" value="en">English</MenuItem>
                <MenuItem key="fr" value="fr">Français</MenuItem>
                <MenuItem key="de" value="de">Deutsch</MenuItem>
                {/* Add more languages as needed */}
              </Select>
            </FormControl>
          </Box>

          <Box  name="pages" sx={{
            right: '90px',
            position: 'absolute',
            flexGrow: 1,
            display: {xs: 'none', md: 'flex'}
          }}>
            <StyledEngineProvider>
              <IopNotificationCenter/>
              <ToastContainer position="bottom-right"
                              autoClose={10000}
                              hideProgressBar={true}
                              newestOnTop={false}
                              closeOnClick
                              rtl={false}
                              draggable
                              theme="light"
              />
            </StyledEngineProvider>
          </Box>

          <Box  name="Menu" sx={{
            right: '10px',
            position: 'absolute',
            flexGrow: 1,
            display: {xs: 'none', md: 'flex'}
          }}>
            <Dropdown>
              <TriggerButton><MenuIcon/></TriggerButton>
              <Menu slots={{ listbox: StyledListbox }}>
                <StyledMenuItem onClick={() => createHandleMenuClick('LOG_OUT')}>
                  {t('headers.btnLogout')}
                </StyledMenuItem>
                <Divider variant="middle"/>
                <Box sx={{ width: 1/2, "margin-left": '10px' }}>
                  <typography>
                    {UserService.getUsername()}
                  </typography>
                </Box>
              </Menu>
            </Dropdown>
          </Box>
          <Typography
            variant="h6"
            noWrap
            component="a"
            href="/"
            sx={{
              mr: 2,
              display: {xs: 'none', md: 'flex'},
              fontFamily: 'monospace',
              fontWeight: 700,
              letterSpacing: '.3rem',
              color: 'inherit',
              textDecoration: 'none',
            }}
          >
          </Typography>
        </Toolbar>
      </Container>
    </AppBar>
  );
}

const blue = {
  50: '#F0F7FF',
  100: '#DAECFF',
  200: '#99CCF3',
  300: '#66B2FF',
  400: '#3399FF',
  500: '#007FFF',
  600: '#0072E5',
  900: '#003A75',
};

const grey = {
  50: '#f6f8fa',
  100: '#eaeef2',
  200: '#d0d7de',
  300: '#afb8c1',
  400: '#8c959f',
  500: '#6e7781',
  600: '#57606a',
  700: '#424a53',
  800: '#32383f',
  900: '#24292f',
};

const StyledListbox = styled('ul')(
  ({ theme }) => `
  font-family: IBM Plex Sans, sans-serif;
  font-size: 0.875rem;
  box-sizing: border-box;
  padding: 6px;
  margin: 12px 0;
  min-width: 200px;
  border-radius: 12px;
  overflow: auto;
  outline: 0px;
  background: ${theme.palette.mode === 'dark' ? grey[900] : '#fff'};
  border: 1px solid ${theme.palette.mode === 'dark' ? grey[700] : grey[200]};
  color: ${theme.palette.mode === 'dark' ? grey[300] : grey[900]};
  box-shadow: 0px 2px 16px ${theme.palette.mode === 'dark' ? grey[900] : grey[200]};
  z-index: 1;
  `,
);

const StyledMenuItem = styled(MenuItem)(
  ({ theme }) => `
  list-style: none;
  padding: 8px;
  border-radius: 8px;
  cursor: default;
  user-select: none;

  &:last-of-type {
    border-bottom: none;
  }

  &.${menuItemClasses.focusVisible} {
    outline: 3px solid ${theme.palette.mode === 'dark' ? blue[600] : blue[200]};
    background-color: ${theme.palette.mode === 'dark' ? grey[800] : grey[100]};
    color: ${theme.palette.mode === 'dark' ? grey[300] : grey[900]};
  }

  &.${menuItemClasses.disabled} {
    color: ${theme.palette.mode === 'dark' ? grey[700] : grey[400]};
  }

  &:hover:not(.${menuItemClasses.disabled}) {
    background-color: ${theme.palette.mode === 'dark' ? grey[800] : grey[100]};
    color: ${theme.palette.mode === 'dark' ? grey[300] : grey[900]};
  }
  `,
);

const TriggerButton = styled(MenuButton)(
  ({ theme }) => `
  font-family: IBM Plex Sans, sans-serif;
  font-weight: 600;
  font-size: 0.875rem;
  box-sizing: border-box;
  border-radius: 8px;
  padding: 8px 16px;
  line-height: 1.5;
  background: transparent;
  border: 1px solid ${theme.palette.mode === 'dark' ? grey[800] : grey[200]};
  color: ${theme.palette.mode === 'dark' ? blue[300] : blue[500]};
  cursor: pointer;

  transition-property: all;
  transition-timing-function: cubic-bezier(0.4, 0, 0.2, 1);
  transition-duration: 120ms;

  &:hover {
    background: ${theme.palette.mode === 'dark' ? grey[800] : grey[50]};
    border-color: ${theme.palette.mode === 'dark' ? grey[600] : grey[300]};
  }

  &:focus-visible {
    border-color: ${blue[400]};
    outline: 3px solid ${theme.palette.mode === 'dark' ? blue[500] : blue[200]};
  }
  `,
);

export default IopHeaderBar;