import 'bootstrap/dist/css/bootstrap.min.css';
import './App.css';
import React from 'react';
import {MemoryRouter as Router} from 'react-router-dom'
import {Route, Routes} from 'react-router-dom'
import IopHeaderBar from './components/IopHeaderBar';
import Validator from './pages/Validator';
import Sandbox from './pages/Sandbox';
import Generator from './pages/Generator';
import {Api} from './pages/Api';
import RenderOnAuthenticated from './components/RenderOnAuthenticated';

// import i18n (needs to be bundled ;))
import './i18n';

function App() {
  return (
    <div>
        <Router>
          <IopHeaderBar/>
          <Routes>
            <Route path="/" element={<RenderOnAuthenticated><Sandbox/></RenderOnAuthenticated>}/>
            <Route path="/Sandbox" element={<RenderOnAuthenticated><Sandbox/></RenderOnAuthenticated>}/>
            <Route path="/Validator" element={<RenderOnAuthenticated><Validator/></RenderOnAuthenticated>}/>
            <Route path="/Generator" element={<RenderOnAuthenticated><Generator/></RenderOnAuthenticated>}/>
            <Route path="/Api" element={<RenderOnAuthenticated><Api/></RenderOnAuthenticated>}/>
          </Routes>
        </Router>
    </div>
  );
}

export default App;
