import React, {useEffect} from "react";
import { Viewer } from '@react-pdf-viewer/core';
import { attachmentPlugin } from '@react-pdf-viewer/attachment';
import UserService from '../services/UserService';

// Import styles
import '@react-pdf-viewer/attachment/lib/styles/index.css';
import '@react-pdf-viewer/core/lib/styles/index.css';

export default function SinglePage(props) {
    const attachmentPluginInstance = attachmentPlugin();

    const { Attachments } = attachmentPluginInstance;

    useEffect(() => {

    }, [])

    const { pdf } = props;

    const getToken = () => {
      return {
        'Authorization': 'Bearer ' + UserService.getToken()
      }
    }

    return (
      <div
        style={{
            border: '1px solid rgba(0, 0, 0, 0.3)',
            display: 'flex',
            height: '100%',
        }}
      >
          <div
            style={{
                borderRight: '1px solid rgba(0, 0, 0, 0.3)',
                overflow: 'auto',
                width: '30%',
            }}
          >
            {/* <Attachments />*/}
          </div>
          <div style={{ flex: 1 }}>

              <Viewer fileUrl={pdf} plugins={[attachmentPluginInstance]} httpHeaders={getToken()} />
          </div>

      </div>
    );
} //