
import { Component } from 'react'
import ReactDOM from 'react-dom'
import config from "../config";

// NOT USED ANYMORE
export class Api extends Component {

  constructor() {
    super();
    this.state = {
      iFrameHeight: '0px'
    }
  }

  render() {
    return (
      <iframe
        style={{maxWidth:2640, width:'100%', height:this.state.iFrameHeight, overflow:'visible'}}
        onLoad={() => setInterval(() => {
          const obj = ReactDOM.findDOMNode(this);
          this.setState({
            "iFrameHeight":  obj.contentWindow.document.body.scrollHeight + 'px'
          });
        }, 200)}
        ref="iframe"
        src={window.location.protocol + '//' + window.location.host + config.endpoints.apiDoc}
        width="100%"
        height={this.state.iFrameHeight}
        title={"Swagger"}
        scrolling="no"
        frameBorder="0"
      />
    );
  }
}
