import React, {useCallback} from 'react';
import ReactFlow, {
  addEdge,
  Background,
  useNodesState,
} from 'reactflow';

import {nodes as initialNodes} from './initialElements';
import IopEmitter from './IopEmitter';
import customEmitStatusEdge from './customEmitStatusEdge';
import customInvoiceReceivedEdge from './customInvoiceReceivedEdge';
import customInvoiceEmitEdge from './customInvoiceEmitEdge';
import customReceivedStatusEdge from './customReceivedStatusEdge';
import customPpfStatusEdge from './customPpfStatusEdge';
import IopReceiver from './IopReceiver';
import IopPdpEmit from './IopPdpEmit';
import IopPdpReceipt from './IopPdpReceipt';
import IopPpf from './IopPpf';
import 'reactflow/dist/style.css';
import './overview.css';
import {EDGES} from './edgesEnum';
import {publish, subscribe, unsubscribe} from '../../controller/event';
import httpService from '../../services/HttpService';
import userService from '../../services/UserService'
import useStateRef from '../../common/useStateRef';
import Slider from '@mui/material/Slider';
import {styled} from '@mui/material/styles';

let eventListToExecute = [];

const nodeTypes = {
  emitter: IopEmitter,
  receiver: IopReceiver,
  pdpEmit: IopPdpEmit,
  pdpReceipt: IopPdpReceipt,
  ppf: IopPpf,
};

const edgeTypes = {
  customEmitStatusEdge,
  customInvoiceReceivedEdge,
  customInvoiceEmitEdge,
  customReceivedStatusEdge,
  customPpfStatusEdge
};

const PrettoSlider = styled(Slider)({
  color: '#1976d2',
  height: 5,
  width: 200,
  '& .MuiSlider-track': {
    border: 'none',
  },
  '& .MuiSlider-thumb': {
    height: 16,
    width: 16,
    backgroundColor: '#fff',
    border: '2px solid currentColor',
    '&:focus, &:hover, &.Mui-active, &.Mui-focusVisible': {
      boxShadow: 'inherit',
    },
    '&:before': {
      display: 'none',
    },
  },
  '& .MuiSlider-valueLabel': {
    lineHeight: 1.2,
    fontSize: 12,
    background: 'unset',
    padding: 0,
    width: 32,
    height: 32,
    borderRadius: '50% 50% 50% 0',
    backgroundColor: '#1976d2',
    transformOrigin: 'bottom left',
    transform: 'translate(50%, -100%) rotate(-45deg) scale(0)',
    '&:before': {display: 'none'},
    '&.MuiSlider-valueLabelOpen': {
      transform: 'translate(50%, -100%) rotate(-45deg) scale(1)',
    },
    '& > *': {
      transform: 'rotate(45deg)',
    },
  },
});

const marks = [
  {
    value: 3,
    label: 'Très lent',
  },
  {
    value: 6,
    label: 'Lent',
  },
  {
    value: 10,
    label: 'Normal',
  }
];

const IopReactFlow = () => {
  let [intervalId, setIntervalId] = useStateRef(null);

  function handleSliderOnMouseUp(event, value) {
    clearInterval(intervalId());
    const timer = startTimer((10 - value) * 1000)
    setIntervalId(timer);
  }

  function valuetext(value) {
    return `${value}°C`;
  }

  function valueLabelFormat(value) {
    return marks.findIndex((mark) => mark.value === value) + 1;
  }

  initialNodes.push({
    id: 'Slider',
    type: 'default',
    position: {x: -730, y: 400},
    className: 'annotation',
    draggable: false,
    style: {
      boxShadow: 'none'
    },
    data: {
      label: (
        <div>
          <PrettoSlider
            marks={marks}
            valueLabelDisplay="auto"
            aria-label="iopole slider"
            max={10}
            min={2}
            valueLabelFormat={valueLabelFormat}
            getAriaValueText={valuetext}
            step={null}
            defaultValue={10}
            onChangeCommitted={handleSliderOnMouseUp}
          />
        </div>
      ),
    },
  })

  const [nodes, setNodes, onNodesChange] = useNodesState(initialNodes);
  const [edges, setEdges] = useStateRef([]);

  const onConnect = useCallback((params) => setEdges((eds) => addEdge(params, eds)), []);

  /**
   *
   */
  const handleStatusIssued = () => {
    if (!isEdgeExist(EDGES.PDP_EMIT_TO_PDP_RECEIPT.id)) {
      setEdges(addEdge(EDGES.PDP_EMIT_TO_PDP_RECEIPT, edges()));
    }
  }

  /**
   *
   * @param event
   */
  const handleInvoiceReceived = (event) => {
    setEdges(addEdge(EDGES.PDP_EMIT_TO_PDP_RECEIPT, edges()));

    setTimeout(() => {
      setEdges(addEdge({
        ...EDGES.PDP_RECEIPT_TO_OD_RECEIPT,
        data: event?.detail
      }, edges()));
      publish('@event/INVOICE_RECEIVED', event?.detail);
    }, 2000);
  }

  /**
   *
   * @param data
   */
  const handleStatus = (data) => {
    if (!isEdgeExist(EDGES.PDP_EMIT_TO_OD_EMIT.id)) {
      setEdges(addEdge(EDGES.PDP_EMIT_TO_OD_EMIT, edges()));
    }
    setTimeout(() => {
      publish('@event/status-received', data.detail); // Should be pass into PDP_EMIT_TO_OD_EMIT edge component like OD_EMIT_TO_PDP_EMIT one
    }, 1000); // Must be kept, otherwise we loose the ISSUED status (cause the edge is not created yet)
  }

  /**
   * Triggered when click on generate or upload on emitter component.
   *
   * @param event
   * @returns {Promise<void>}
   */
  const handleEmitFromOdToPdpEmit = async (event) => {

    // Must be create before generate code, because status from pdp come back to soon, graph wont be very readable.
    if (!isEdgeExist(EDGES.OD_EMIT_TO_PDP_EMIT.id)) {
      setEdges(addEdge(EDGES.OD_EMIT_TO_PDP_EMIT, edges()));
    }

    const codes = await generateCodeHelp(event.detail.configuration, event.detail.parameters);

    setTimeout(() => {
      const edgeFound = getEdgeById(EDGES.OD_EMIT_TO_PDP_EMIT.id);

      edgeFound.data = {
        codes: [...codes]
      };
    }, 1000); // Wait for the previous edge to be created
  }

  /**
   * Triggered when click on button on receipt side
   *
   * @param event
   * @returns {Promise<void>}
   */
  const handleReceiptFromOdToPdpReceipt = async (event) => {
    const codes = await generateCodeHelp(event.detail.configuration, event.detail.parameters);

    if (!isEdgeExist(EDGES.OD_RECEIPT_TO_PDP_RECEIPT.id)) {
      setEdges(addEdge({
        ...EDGES.OD_RECEIPT_TO_PDP_RECEIPT, data: {
          codes
        }
      }, edges()));
    } else {
      const edgeFound = getEdgeById(EDGES.OD_RECEIPT_TO_PDP_RECEIPT.id);
      edgeFound.data.codes.push(...codes);
    }
  }

  /**
   * Triggered when websocket received ppf notification
   *
   * @param event
   * @returns {Promise<void>}
   */
  const handlePpfReceived = (event) => {
    if (!isEdgeExist(EDGES.PDP_EMIT_TO_PPF.id)) {
      setEdges(addEdge(EDGES.PDP_EMIT_TO_PPF, edges()));
    }
    setTimeout(() => {
      publish('@event/PPF_STATUS_RECEIVED', event.detail);
    }, 2000);
  }

  /**
   * Triggered when websocket received ppf notification
   *
   * @param event
   * @returns {Promise<void>}
   */
  const handleInvoicePayed = (event) => {
    publish('@event/INVOICE_PAYED', event?.detail);
  }

  /**
   * Triggered when websocket received ppf notification
   *
   * @param event
   * @returns {Promise<void>}
   */
  const handlePaymentReceived = (event) => {
    publish('@event/PAYMENT_RECEIVED', event?.detail);
  }

  function resetAll() {
    eventListToExecute = [];
    return setEdges([]);
  }

  function isEdgeExist(edgeIdToFInd) {
    return edges().some((edge) => edge.id === edgeIdToFInd);
  }

  function getEdgeById(edgeIdToFind) {
    return edges().find((edge) => edge.id === edgeIdToFind);
  }

  /**
   *
   * @param delay: number
   * @returns {NodeJS.Timer}
   */
  function startTimer(delay) {
    return setInterval(() => {
      const functionToExecute = eventListToExecute.shift();

      if (functionToExecute && typeof functionToExecute === 'function') {
        functionToExecute().then().catch((error) => {
          console.error(error);
        });
      }
    }, delay);
  }

  React.useEffect(() => {
    setIntervalId(startTimer(1000));

    return () => {
      clearInterval(intervalId());
    }
  }, [])

  function pushElementToEventList(functionToExecute, event) {
    eventListToExecute.push(async () => {
      await functionToExecute(event)
    });
  }

  React.useEffect(() => {
    const pushElementEmitFromOdToPdpEmit = (event) => pushElementToEventList(handleEmitFromOdToPdpEmit, event);
    const pushElementReceiptFromOdToPdpReceipt = (event) => pushElementToEventList(handleReceiptFromOdToPdpReceipt, event);
    const pushElementHandleStatusIssued = (event) => pushElementToEventList(handleStatusIssued, event);
    const pushElementHandleInvoiceReceived = (event) => pushElementToEventList(handleInvoiceReceived, event);
    const pushElementHandleStatus = (event) => pushElementToEventList(handleStatus, event);
    const pushElementHandlePpfReceived = (event) => pushElementToEventList(handlePpfReceived, event);
    const pushElementHandleInvoicePayed = (event) => pushElementToEventList(handleInvoicePayed, event);
    const pushElementHandlePaymentReceived = (event) => pushElementToEventList(handlePaymentReceived, event);

    subscribe('@event/RESET_ALL', resetAll);
    subscribe('@event/OD_EMIT_TO_PDP_EMIT', pushElementEmitFromOdToPdpEmit);
    subscribe('@event/OD_RECEIPT_TO_PDP_RECEIPT', pushElementReceiptFromOdToPdpReceipt);

    subscribe('status-issued', pushElementHandleStatusIssued);
    subscribe('invoice-received', pushElementHandleInvoiceReceived);
    subscribe('status', pushElementHandleStatus);
    subscribe('status-ppf-received', pushElementHandlePpfReceived);
    subscribe('status-payed', pushElementHandleInvoicePayed);
    subscribe('payment-received', pushElementHandlePaymentReceived);

    return () => {
      unsubscribe('@event/RESET_ALL', resetAll);
      unsubscribe('@event/OD_EMIT_TO_PDP_EMIT', pushElementEmitFromOdToPdpEmit);
      unsubscribe('@event/OD_RECEIPT_TO_PDP_RECEIPT', pushElementReceiptFromOdToPdpReceipt);

      unsubscribe('status-issued', pushElementHandleStatusIssued);
      unsubscribe('invoice-received', pushElementHandleInvoiceReceived);
      unsubscribe('status', pushElementHandleStatus);
      unsubscribe('status-ppf-received', pushElementHandlePpfReceived);
      unsubscribe('status-payed', pushElementHandleInvoicePayed);
      unsubscribe('payment-received', pushElementHandlePaymentReceived);
    }
  }, []);

  const proOptions = {hideAttribution: true};

  /**
   *
   * @param configuration what we need to generate ? directoryGet, emitInvoice ...
   * @param parameters parameters to add to request
   * @returns {Promise<*[]>}
   */
  async function generateCodeHelp(configuration, parameters) {
    const codes = [];

    if (configuration.directoryGet) {
      const offset1 = parameters.offset1;
      const offset2 = parameters.offset2;
      const limit = parameters.limit;

      const generatedDirectoryCallCode1 = await httpService.getDirectoryCallGeneratedCode(offset1, limit);
      const generatedDirectoryCallCode2 = await httpService.getDirectoryCallGeneratedCode(offset2, limit);

      codes.push({
        groupName: 'Récupération aléatoire d\'un fournisseur et d\'un acheteur dans l\'annuaire pour génération d\'une facture',
        groupCodes: [
          {
            generatedCode: generatedDirectoryCallCode1,
            name: `Récupération aléatoire d'un acheteur dans l'annuaire`
          },
          {
            generatedCode: generatedDirectoryCallCode2,
            name: 'Récupération aléatoire d\'un fournisseur dans l\'annuaire'
          },
        ]
      });
    }

    if (configuration.emitInvoice) {
      const fileId = parameters.fileId;
      const emitInvoiceCallCode = await httpService.getEmitInvoiceCallGeneratedCode(fileId);

      codes.push({
        groupName: 'Émission de la facture vers iopole',
        groupCodes: [
          {
            generatedCode: emitInvoiceCallCode,
            name: 'Émission de la facture'
          }]
      });
    }

    if (configuration.emitStatus) {
      const id = parameters.id;
      const message = parameters.message;
      const statusSuccess = parameters.statusSuccess;
      const invoiceId = parameters.invoiceId;

      let receiptInvoiceCallCode;
      if (id === 'PAYMENT_SENT') {
        const amount = parameters.amount;
        const currency = parameters.currency;

        receiptInvoiceCallCode = await httpService.getEmitStatusCallGeneratedCode(invoiceId, id, message, amount, currency);

      } else {
        receiptInvoiceCallCode = await httpService.getEmitStatusCallGeneratedCode(invoiceId, id, message);
      }

      codes.push({
        groupName: `Emission du statut ${message} vers la PDP..`,
        groupCodes: [
          {
            generatedCode: receiptInvoiceCallCode,
            name: 'Emission d\'un statut'
          }]
      });
    }

    return codes;
  }

  /**
   *
   */
  return (
    <ReactFlow
      nodes={nodes}
      edges={edges()}
      onNodesChange={onNodesChange}
      zoomOnScroll={false} // Disable zooming via scroll
      panOnScroll={false}  // Disable panning via scroll
      onConnect={onConnect}
      panOnDrag={false}
      selectionOnDrag={false}
      preventScrolling={false}
      minZoom={1.3}
      maxZoom={1.3}
      fitView
      nodeTypes={nodeTypes}
      edgeTypes={edgeTypes}
      proOptions={proOptions}
    >
      <Background color="blue" gap={10} size={0.6}/>
    </ReactFlow>
  );
};

export default IopReactFlow;
