const config = {
  endpoints: {
    upload: '/v1/upload/file/',
    download: '/v1/download/file/',
    validate: '/v1/invoice/validate/fileid/',
    generate: '/v1/invoice/generate/fileid/',
    generateFromJson: '/v1/invoice/generate',
    generateMultipleFromJson: '/v1/invoice/generate/multiple',
    registerUser: '/v1/user/register',
    createSandbox: '/v1/sandbox/create',
    removeSandbox: '/v1/sandbox/remove',
    apiDoc:"/api-docs",
    pdp: {
      directory: {
        get: '/v1/pdp/directory',
        getFr: '/v1/pdp/directory/fr/search',
      },
      participant: {
        get: '/v1/pdp/participant/:value',
        create: '/v1/pdp/participant',
        addInNetwork: '/v1/pdp/participant/:participantId/network',
      },
      emit: '/v1/pdp/send/invoice/fileid/',
      status: '/v1/pdp/output/invoice/:invoiceId/status/'
    }
  },
  backend: {
    url: process.env.REACT_APP_BACK_URL
  },
  other: {
    documentationUrl: process.env.REACT_APP_DOCUMENTATION_URL,
    apiUrl: process.env.REACT_APP_API_URL
  },
  keycloak: {
    realm: process.env.REACT_APP_KEYCLOAK_REALM || "lab",
    clientId: process.env.REACT_APP_KEYCLOAK_CLIENT_ID || "iopole-lab-front"
  }
}

module.exports = config