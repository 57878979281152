import http from "../httpCommon";
import UserService from './UserService';
const config = require('../config/');

class UploadFilesService {
  upload(file, onUploadProgress) {
    let formData = new FormData();

    formData.append("file", file);
    return http.post(config.endpoints.upload, formData, {
      headers: {
        "Content-Type": "multipart/form-data",
        'Authorization': 'Bearer ' + UserService.getToken()
      },
      onUploadProgress,
    });
  }

  getFiles(fileId) {
    //return http.get("/files");
    return null;
  }
}

export default new UploadFilesService();