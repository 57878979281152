import HttpService from '../services/HttpService';
import UserService from '../services/UserService';
import {connect} from '../services/SocketService';
import {webSocketListener} from './websocketController';

async function init() {
  try {
    // Save user if not exists and get it from db.
    const user = await HttpService.registerAndGetUser();

    // Set user in "global context" UserService
    UserService.setUser(user);

    // Start websocket connection
    await connect(UserService.getToken());

    // Start registering and listening for websocket events
    webSocketListener();

  } catch (error) {
    console.error('Unexpected error');
    throw error;
  }
}

const InitController = {
  init
};

export default InitController;