import {getBezierPath, EdgeLabelRenderer, BaseEdge, MarkerType} from 'reactflow';
import * as React from 'react';
import Button from '@mui/material/Button';
import Dialog from '@mui/material/Dialog';
import IconButton from '@mui/material/IconButton';
import Typography from '@mui/material/Typography';
import Accordion from '@mui/material/Accordion';
import AccordionDetails from '@mui/material/AccordionDetails';
import AccordionSummary from '@mui/material/AccordionSummary';
import ExpandMoreIcon from '@mui/icons-material/ExpandMore';
import IntegrationInstructionsIcon from '@mui/icons-material/IntegrationInstructions';

import {
  DialogActions,
  DialogContent,
  DialogContentText,
  DialogTitle
} from '@mui/material';
import Box from '@mui/material/Box';
import moment from 'moment/moment';
import CodeGeneratorDisplay from './codeGeneratorDisplay';

moment.locale('fr');

const CustomReceivedEdge = ({
                      id,
                      sourceX,
                      sourceY,
                      targetX,
                      targetY,
                      sourcePosition,
                      targetPosition,
                      markerEnd,
                      data
                    }) => {
  const [edgePath, labelX, labelY] = getBezierPath({
    sourceX,
    sourceY,
    sourcePosition,
    targetX,
    targetY,
    targetPosition,
  });

  const [open, setOpen] = React.useState(false);

  const handleClickOpen = () => {
    setOpen(true);
  };

  const handleClose = () => {
    setOpen(false);
  };

  return (
    <>
      <BaseEdge id={id} type={'edge'} path={edgePath} markerEnd={markerEnd} style={{
        strokeWidth: 2,
        stroke: '#005EFF'
      }}/>
      <EdgeLabelRenderer>
        {data?.codes && (
          <div
            style={{
              position: 'absolute',
              transform: `translate(-30%, -140%) translate(${sourceX}px,${sourceY}px)`,
              backgroundColor: 'transparent',
              borderRadius: '50%',
              border: 20,
              fontSize: '10px',
              padding: 0,
              fontWeight: 100,
              pointerEvents: 'all'
            }}
          >
            <IconButton
              edge="start"
              color="black"
              onClick={handleClickOpen}
              aria-label="open"
            >
              <IntegrationInstructionsIcon sx={{marginLeft: -6}}/>
            </IconButton>
            <Dialog
              fullWidth={true}
              maxWidth="xl"
              open={open}
              onClose={handleClose}
            >
              <DialogTitle>Liste des requêtes HTTP émises vers la plateforme de reception iopole...</DialogTitle>
              <DialogContent>
                <Box
                  noValidate
                  component="form"
                  sx={{
                    display: 'flex',
                    flexDirection: 'column'
                  }}
                >
                  <CodeGeneratorDisplay codes={{code: data?.codes}}/>
                </Box>
              </DialogContent>
              <DialogActions>
                <Button onClick={handleClose}>Fermer</Button>
              </DialogActions>
            </Dialog>
          </div>
        )}
      </EdgeLabelRenderer>
    </>
  );
};

export default CustomReceivedEdge;
