import React from 'react';
import {getBezierPath, EdgeLabelRenderer, BaseEdge, EdgeText} from 'reactflow';
import AttachEmailIcon from '@mui/icons-material/AttachEmail';
import IconButton from '@mui/material/IconButton';
import IntegrationInstructionsIcon from '@mui/icons-material/IntegrationInstructions';
import {DialogActions, DialogContent, DialogTitle} from '@mui/material';
import Box from '@mui/material/Box';
import CodeGeneratorDisplay from './codeGeneratorDisplay';
import Button from '@mui/material/Button';
import Dialog from '@mui/material/Dialog';

// this is a little helper component to render the actual edge label
function EdgeLabel({ transform, label, codes }) {

  return (
    <div
      style={{
        position: 'absolute',
        background: 'transparent',
        padding: 6,
        pointerEvents: 'all',
        transform,
      }}
      className="nodrag nopan"
    >
      {label}
    </div>
  );
}

// this is a little helper component to render the actual edge label
function Number({ transform, label, codes }) {

  return (
    <div
      style={{
        position: 'absolute',
        background: 'transparent',
        color: 'white',
        padding: 6,
        transform,
        backgroundColor: 'blue',
        borderRadius: '50%'
      }}
      className="nodrag nopan"
    >
      {label}
    </div>
  );
}

const CustomEmitEdge = ({
  id,
  sourceX,
  sourceY,
  targetX,
  targetY,
  sourcePosition,
  targetPosition,
  data,
  markerEnd
}) => {
  const [edgePath] = getBezierPath({
    sourceX,
    sourceY,
    sourcePosition,
    targetX,
    targetY,
    targetPosition,
  });

  const [open, setCodeOpen] = React.useState(false);

  const handleOpen = () => {
    setCodeOpen(true);
  };

  const handleClose = () => {
    setCodeOpen(false);
  };

  return (
    <>
      <BaseEdge id={id} path={edgePath} markerEnd={markerEnd} style={{strokeWidth: 2,stroke: '#005EFF'}}/>
      <EdgeLabelRenderer>
        {data?.codes && (
          <div
            style={{
              position: 'absolute',
              background: 'transparent',
              padding: 6,
              transform: `translate(-30%, -80%) translate(${sourceX}px,${sourceY}px)`,
              pointerEvents: 'all'
            }}
          >
            <IconButton
              color="black"
              aria-label="open"
              onClick={() => handleOpen()}>
              <IntegrationInstructionsIcon sx={{marginLeft: 2}}/>
            </IconButton>
            <Dialog
              fullWidth={true}
              maxWidth="xl"
              open={open}
              onClose={handleClose}
            >
              <DialogTitle>Liste des requêtes HTTP émises vers la plateforme d'emission iopole...</DialogTitle>
              <DialogContent>
                <Box
                  noValidate
                  component="form"
                  sx={{
                    display: 'flex',
                    flexDirection: 'column'
                  }}
                >
                  <CodeGeneratorDisplay codes={{code: data?.codes}}/>
                </Box>
              </DialogContent>
              <DialogActions>
                <Button onClick={handleClose}>Fermer</Button>
              </DialogActions>
            </Dialog>
          </div>
        )}
        {/*<EdgeLabel
          transform={`translate(-1500%, -80%) translate(${targetX}px,${targetY}px)`}
          label={<Number label={1}/>}
        />*/}
        {data?.endLabel && (
          <EdgeLabel
            transform={`translate(-90%, -80%) translate(${targetX}px,${targetY}px)`}
            label={<AttachEmailIcon color={'success'}/>}
          />
        )}
      </EdgeLabelRenderer>
    </>
  );
};

export default CustomEmitEdge;
