import React, { useRef } from 'react';
import Button from "@mui/material/Button";
import {Translation} from "react-i18next";

const FileUploadButton = ({ btnName, acceptExt, onFilesSelected }) => {
  const fileInputRef = useRef(null);

  const handleFileSelect = (event) => {
    const files = event.target.files;
    if (onFilesSelected && files) {
      onFilesSelected(files); // Call the callback with the file name
    }
  };

  const openFileDialog = () => {
    fileInputRef.current.click(); // Trigger the file input click
  };

  return (
    <div>
      <input
        type="file"
        onChange={handleFileSelect}
        accept={acceptExt}
        style={{ display: 'none' }}
        id="contained-button-file"
      />
      <label htmlFor="contained-button-file">
        <Button variant="contained" color="primary" component="span">
          <Translation>{t => <span>{btnName}</span>}</Translation>
        </Button>
      </label>
    </div>
  );
};

export default FileUploadButton;
