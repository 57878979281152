import * as React from 'react';
import UserService from '../services/UserService'
import IopSandboxNotCreated from '../components/IopSandboxNotCreated'

import {toast} from 'react-toastify';
import {useTranslation} from 'react-i18next';
import IopSandboxPanel from '../components/IopSandboxPanel';

function Sandbox() {
  const [statusActivated, setStatusActivated] = React.useState(UserService.sandboxActivated);
  const {t, i18n} = useTranslation();

  const sandboxCreated = () => {
    toast.success(t('sandbox.create.success'));
    setStatusActivated(true);
  }

  const sandboxDeleted = () => {
    toast.success(t('sandbox.delete.success'));
    setStatusActivated(false);
  }

  const sandboxError = () => {
    toast.error(t('sandbox.global.error'));
    setStatusActivated(false);
  }

  React.useEffect(() => {
    document.addEventListener('sandbox-created', sandboxCreated);
    document.addEventListener('sandbox-deleted', sandboxDeleted);
    document.addEventListener('sandbox-error', sandboxError);

    return () => {
      document.removeEventListener('sandbox-created', sandboxCreated);
      document.removeEventListener('sandbox-deleted', sandboxDeleted);
      document.removeEventListener('sandbox-error', sandboxError)
    }
  }, []);

  return (
    <div className="app">
      {!statusActivated ? <IopSandboxNotCreated/> : null}
      {statusActivated ? <IopSandboxPanel/> : null}
    </div>
  );
}

export default Sandbox;