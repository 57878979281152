import React from "react";
import {Translation} from "react-i18next";
import UploadFiles from "../components/uploadFilesComponent";
import IopValidation from "../components/IopValidation";

function Validator() {
  return (
    <div>
      <Translation>
        {t => <UploadFiles config={{eventName:"validate", acceptExt:".pdf,.xml,.json", noSelection:t('validate.noSelection')}}/>}
      </Translation>
      <br/><br/>
      <IopValidation/>
    </div>
  );
}

export default Validator;