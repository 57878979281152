import * as React from 'react';
import Box from '@mui/material/Box';
import UserService from '../services/UserService';
import Button from '@mui/material/Button';
import HttpService from '../services/HttpService';
import {toast} from 'react-toastify';
import IconButton from '@mui/material/IconButton';
import {Visibility, VisibilityOff} from '@mui/icons-material';
import {Card, DialogActions, DialogContent, DialogContentText, DialogTitle} from '@mui/material';
import ContentCopyIcon from '@mui/icons-material/ContentCopy';
import Dialog from '@mui/material/Dialog';

import config from '../config/index';

function IopSandboxSetting() {

  const [createSandboxButtonDisable, setCreateSandboxButtonDisable] = React.useState(false);

  const CopyToClipboardButton = ({value}) => {
    const handleClick = () => {
      navigator.clipboard.writeText(value);
      toast.info('Copié !', {position: 'top-center', autoClose: 500});
    };

    return (
      <>
        <IconButton onClick={handleClick} color="primary">
          <ContentCopyIcon/>
        </IconButton>
      </>
    );
  };


  const ConfidentialText = ({text}) => {
    const [hidden, setHidden] = React.useState(true);

    const toggleVisibility = () => {
      setHidden(!hidden);
    };

    const toggleHide = () => {
      setHidden(true);
    };

    return (
      <div>
        <strong>client_secret: </strong>
        {hidden &&
          <span>***************************</span>}
        {!hidden &&
          <span>{text}</span>}
        <IconButton
          onClick={toggleVisibility}
          onMouseDown={toggleHide}
        >
          {hidden && text &&
            <div>
              <Visibility/>
            </div>
          }
          {!hidden && text &&
            <div>
              <VisibilityOff/>
            </div>
          }
        </IconButton>
        <CopyToClipboardButton value={text}/>
      </div>
    );
  };


  /**
   *
   * @returns {Promise<void>}
   */
  async function removeSandbox() {
    setOpen(false);
    try {
      await HttpService.removeSandbox();
      setCreateSandboxButtonDisable(true)
      toast.info('Votre sandbox est en cours de suppression, vous recevrez une notification lorsqu\'elle sera définitivement supprimé.')

    } catch (error) {
      console.log(JSON.stringify(error));
      console.log(error);
      setCreateSandboxButtonDisable(false)
      toast.error('Une erreur est survenue lors de la suppression de votre sandbox. Réssayez plus tard ou contactez support@iopole.com')
    }
  }

  const handleClose = () => {
    setOpen(false);
  };

  const [open, setOpen] = React.useState(false);

  return (
    <Card sx={{width: 530}}>
      <div>
        <Box
          sx={{
            p: 2,
            marginTop: '1px',
          }}
        >
          <strong>client_id: </strong> <span>{UserService.sandboxClientId()}</span><br/>
          <ConfidentialText sx={{position: 'relative'}} text={UserService.sandboxClientSecret()}/>
          {/*<a target="_blank" rel="noopener noreferrer"  href="https://api.ppd.iopole.fr/v1/api"><strong>Documentation</strong></a>*/}
          <strong>API(s): </strong> <a target="_blank" rel="noopener noreferrer" href="https://api.ppd.iopole.fr/v1/api"><strong>Documentation API</strong></a>
          <br/><br/>
          <Button
            disabled={createSandboxButtonDisable}
            size={'small'}
            variant="contained"
            color="info"
            onClick={() => setOpen(true)}
          >
            Supprimer ma sandbox
          </Button>

          <Dialog
            fullWidth={true}
            maxWidth="xl"
            open={open}
            onClose={handleClose}
          >
            <DialogTitle>Vous êtes sur le point de supprimer définitivement votre sandbox</DialogTitle>
            <DialogContent>
              <DialogContentText>
                Toutes vos données et votre configuration sera supprimé, Confirmez vous ?
              </DialogContentText>
              <DialogActions>
                <Button color="error" onClick={async () => {
                  await removeSandbox();
                }}>Supprimer</Button>
                <Button onClick={handleClose}>Conserver</Button>
              </DialogActions>
            </DialogContent>
          </Dialog>

        </Box>
      </div>
    </Card>
  )
}

export default IopSandboxSetting;