import {io} from "socket.io-client";

let socket;

async function connect(token) {
    socket = new io(window.location.protocol + '//' + window.location.host, {
       auth: {
           token: token
       }
   });
}

function on(event, callback) {
    socket.on(event, async (data) => {
        console.log('web socket received  !' + event);

        await callback(data);
    });
}

export {
    connect,
    on
}