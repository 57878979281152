import React from 'react';
import {getBezierPath, EdgeLabelRenderer, BaseEdge} from 'reactflow';
import config from '../../config';
import {Buffer} from 'buffer';
import Box from '@mui/material/Box';
import IconButton from '@mui/material/IconButton';
import PictureAsPdfIcon from '@mui/icons-material/PictureAsPdf';
import Dialog from '@mui/material/Dialog';
import {DialogActions, DialogContent, DialogContentText, DialogTitle} from '@mui/material';
import SinglePagePDFViewer from '../../common/single-page';
import Button from '@mui/material/Button';
import AccordionDetails from '@mui/material/AccordionDetails';
import TabContext from '@mui/lab/TabContext';
import TabList from '@mui/lab/TabList';
import Tab from '@mui/material/Tab';
import TabPanel from '@mui/lab/TabPanel';
import SyntaxHighlighter from 'react-syntax-highlighter';
import {github} from 'react-syntax-highlighter/dist/cjs/styles/hljs';
import vkbeautify from 'vkbeautify';
import useStateRef from '../../common/useStateRef';
import moment from 'moment';
import {subscribe, unsubscribe} from '../../controller/event';
import ArticleIcon from '@mui/icons-material/Article';
import Accordion from '@mui/material/Accordion';
import AccordionSummary from '@mui/material/AccordionSummary';
import ExpandMoreIcon from '@mui/icons-material/ExpandMore';
import Typography from '@mui/material/Typography';

// this is a little helper component to render the actual edge label
function EdgeLabel({transform, label}) {
  return (
    <div
      style={{
        position: 'absolute',
        background: 'transparent',
        padding: 10,
        pointerEvents: 'all',
        transform,
      }}
      className="nodrag nopan"
    >
      {label}
    </div>
  );
}

function RenderPdf({pdfInfo}) {

  const data = pdfInfo.data;

  const [pdfOpen, setPdf] = React.useState(false);
  const [invoiceId, setInvoiceId] = React.useState(null);
  const [filePathUrl, setFilePathUrl] = React.useState(null);

  React.useEffect(() => {
    handleSetInvoiceId(data?.invoiceId);
    handleSetFilePathUrl(window.location.protocol + '//' + window.location.host + config.endpoints.download + new Buffer(data.filename).toString('base64'));
  }, []);

  const handleSetInvoiceId = (invoiceId) => {
    setInvoiceId(invoiceId);
  };

  const handleSetFilePathUrl = (filepath) => {
    setFilePathUrl(filepath);
  };

  const handlePdfOpen = () => {
    setPdf(true);
  };

  const handlePdfClose = () => {
    setPdf(false);
  };

  return (
    <div>
      <Box>
        <IconButton
          color="black"
          aria-label="open"
          onClick={() => handlePdfOpen()}>
          <PictureAsPdfIcon sx={{
            marginLeft: 2,
            color: 'red',
            bgcolor: 'background.paper',
            border: '1px solid #000',
            boxShadow: 4,
            width: 30,
            p: 0.1
          }}/>
        </IconButton>
      </Box>
      <Dialog
        fullWidth={true}
        maxWidth="xl"
        open={pdfOpen}
        onClose={handlePdfClose}
      >
        <DialogTitle>Facture reçue</DialogTitle>
        <DialogContent>
          <Box
            noValidate
            component="form"
            sx={{
              display: 'flex',
              flexDirection: 'column'
            }}
          >
            <SinglePagePDFViewer pdf={filePathUrl}/>}
          </Box>
        </DialogContent>
        <DialogActions>
          <Button onClick={handlePdfClose}>Fermer</Button>
        </DialogActions>
      </Dialog>
    </div>
  );
}

const CustomEdge = ({
                      id,
                      sourceX,
                      sourceY,
                      targetX,
                      targetY,
                      sourcePosition,
                      targetPosition,
                      data,
                      markerEnd
                    }) => {
  const [edgePath, labelX, labelY] = getBezierPath({
    sourceX,
    sourceY,
    sourcePosition,
    targetX,
    targetY,
    targetPosition,
  });

  const renderContent = (item) => {

    return <Box>
      <AccordionDetails>
        <TabContext value={value}>
          <Box sx={{borderBottom: 1, borderColor: 'divider'}}>
            <TabList onChange={handleChangeTab} aria-label="lab API tabs example">
              <Tab label="XML" value="1"/>
              <Tab label="JSON" value="2"/>
            </TabList>
          </Box>
          <TabPanel value="1">
            <SyntaxHighlighter language="xml" style={github}>
              {item.xml}
            </SyntaxHighlighter>
          </TabPanel>
          <TabPanel value="2">
            <SyntaxHighlighter language="json" style={github}>
              {vkbeautify.json(item.json)}
            </SyntaxHighlighter>
          </TabPanel>
        </TabContext>
      </AccordionDetails>
    </Box>
  }

  const STATUS_COLOR = {
    FAILURE: '#FF0072',
    SUCCESS: '#00ff3c',
    INVOICE: '#005EFF'
  };

  const STATUS_RECEIVED_CSS = {
    p: 2,
    border: '2px dashed red',
    borderRadius: '60%',
    transition: 'all 0.5s ease-in-out'
  };
  const STATUS_CSS = {
    backgroundColor: '#f5f5f6',
    borderRadius: '60%',
    transition: 'all 0.5s ease-in-out'
  };

  const [statusColor, setStatusColor] = React.useState(STATUS_COLOR.INVOICE);
  const [expanded, setExpanded] = React.useState(false);
  const [getListItemsState, setListItemsState] = useStateRef([]);
  const [expandedFirst, setExpandedFirst] = React.useState('none');
  const [getStyle, setStyle] = React.useState(STATUS_CSS);

  const handleFirstLevelExpand = (panel) => (event, isExpanded) => {
    setExpandedFirst(isExpanded ? panel : false);
  };

  const handleChange = (panel) => (event, isExpanded) => {
    setExpanded(isExpanded ? panel : false);
  };

  const [value, setValue] = React.useState('1');

  const handleChangeTab = (event, newValue) => {
    setValue(newValue);
  };

  const [open, setOpen] = React.useState(false);

  const handleClickOpen = () => {
    setOpen(true);
  };

  const handleClose = () => {
    setOpen(false);
  };

  const reset = () => {
    setListItemsState([]);
    setStatusColor(STATUS_COLOR.INVOICE);
  };

  const handleStatusReceived = (data) => {
    setStyle(STATUS_RECEIVED_CSS);
    setTimeout(() => {
      setStyle(STATUS_CSS)
    }, 500);

    if (data?.detail) {
      if (statusColor === STATUS_COLOR.INVOICE) {
        setStatusColor(STATUS_COLOR[data.detail?.statusSuccess ? 'SUCCESS' : 'FAILURE']);

      } else if (statusColor === STATUS_COLOR.SUCCESS && !data.detail?.statusSuccess) {
        setStatusColor(STATUS_COLOR.FAILURE);
      }
      setListItemsState([{...data?.detail, date: moment().format('YYYY-MM-DD HH:mm:ss.SSS')}, ...getListItemsState()]);
    }
  };

  React.useEffect(() => {
    subscribe('@event/PAYMENT_RECEIVED', handleStatusReceived);
    subscribe('@event/RESET_ALL', reset);

    return () => {
      unsubscribe('@event/PAYMENT_RECEIVED', handleStatusReceived);
      unsubscribe('@event/RESET_ALL', reset);
    }
  }, []);

  return (
    <>
      <BaseEdge id={id} path={edgePath} markerEnd={markerEnd} style={{
        strokeWidth: 2,
        stroke: statusColor
      }}/>
      <EdgeLabelRenderer>
        {data.invoiceId && (
          <EdgeLabel
            transform={`translate(-90%, -80%) translate(${targetX}px,${targetY}px)`}
            label={<RenderPdf pdfInfo={{data}} color={'success'}/>}
          />
        )}
        <div
          style={{
            position: 'absolute',
            transform: `translate(-50%, -50%) translate(${labelX}px,${labelY}px)`,
            backgroundColor: 'transparent',
            borderRadius: '50%',
            border: 20,
            fontSize: '10px',
            padding: 0,
            fontWeight: 100,
            pointerEvents: 'all'
          }}
        >
          <IconButton
            edge="start"
            color="black"
            onClick={handleClickOpen}
            aria-label="open"
          >
            <ArticleIcon sx={getStyle}/>
          </IconButton>
          <Dialog
            fullWidth={true}
            maxWidth="xl"
            open={open}
            onClose={handleClose}
          >
            <DialogTitle>Liste des statuts reçus depuis votre PDP de réception</DialogTitle>
            <DialogContent>
              <DialogContentText>
                Trier par date
              </DialogContentText>
              <Box
                noValidate
                component="form"
                sx={{
                  display: 'flex',
                  flexDirection: 'column'
                }}
              >
                {getListItemsState() &&
                  getListItemsState().map((item, i) => (
                    <Accordion key={item.id} expanded={expanded === 'panel' + i} onChange={handleChange('panel' + i)}>
                      <AccordionSummary
                        expandIcon={<ExpandMoreIcon/>}
                        aria-controls="panel1bh-content"
                        id="panel1bh-header"
                      >
                        <Typography sx={{width: '25%', flexShrink: 0}}>
                          {item.date}
                        </Typography>
                        <Typography sx={{width: '25%', flexShrink: 0}}>
                          {item.id}
                        </Typography>
                        <Typography sx={{width: '10%', flexShrink: 0}}>
                          {item.code}
                        </Typography>
                        <Typography sx={{width: '40%', flexShrink: 0}}>
                          {item.description}
                        </Typography>
                      </AccordionSummary>
                      <AccordionDetails>
                        {renderContent(item)}
                      </AccordionDetails>
                    </Accordion>
                  ))}
              </Box>
            </DialogContent>
            <DialogActions>
              <Button onClick={handleClose}>Fermer</Button>
            </DialogActions>
          </Dialog>
        </div>
      </EdgeLabelRenderer>
    </>
  );
};

export default CustomEdge;
