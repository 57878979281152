const NODES = {
  OD_EMIT: {
    id: '1',
    type: 'emitter'
  },
  PDP_EMIT: {
    id: '3',
    type: 'pdpEmit',
    statusHandleId: 'pdpEmitStatusSource',
    statusPpfHandleId: 'pdpEmitPpfStatusSource'
  },
  PDP_RECEIPT: {
    id: '4',
    type: 'pdpReceipt',
    statusHandleId: 'pdpReceivedStatusSource'
  },
  PPF: {
    id: '5',
    type: 'ppf'
  },
  OD_RECEIPT: {
    id: '6',
    type: 'receiver'
  },
}

export {NODES}