import {NODES} from './nodeEnum';
import Divider from '@mui/material/Divider';

import * as React from 'react';
import Typography from '@mui/material/Typography';
import {styled} from '@mui/material/styles';
import {Popover, Tooltip} from '@mui/material';
import HelpIcon from '@mui/icons-material/Help';
import LegendToggleIcon from '@mui/icons-material/LegendToggle';
import Button from '@mui/material-next/Button';

function BasicTooltip() {
  return (
    <Tooltip
      title="Le point de vue est toujours celui d'un opérateur de dématérialisation (en emission ou en réception)"
      followCursor>
      <HelpIcon sx={{fontSize: 16, marginLeft: 0.3, marginBottom: 0.6}}/>
    </Tooltip>
  );
}

const LegendContainer = styled('div')({
  display: 'flex',
  alignItems: 'center',
  width: 800
});

const Legend = styled('legend')({
  position: 'inherit',
  fontSize: 12,
  marginLeft: '10px', // Adjust as needed
  flex: 1, // Added to stretch legend section
});

const LegendItem = styled('div')({
  display: 'flex',
  alignItems: 'center',
  marginRight: '10px', // Adjust as needed
});

const Img = styled('img')({
  margin: 'inherit',
  width: 60
});

const ParentLegend = (
  <LegendContainer>
    {/*Faudrait ajouter ce petit layus*/}
    {/*<span>Le point de vue est toujours celui d'un opérateur de dématérialisation (en emission ou en réception)</span>*/}
    <Legend>
      <LegendItem>
        <Img alt="complex" src="green-status.gif" />
        <span>Notification OK</span>
      </LegendItem>
      <LegendItem>
        <Img alt="complex" src="red-status.gif" />
        <span>Notification KO (Refusée, rejetée..)</span>
      </LegendItem>
      <LegendItem>
        <Img alt="complex" src="yellow-stats.gif" />
        <span>Flux a destination du PPF</span>
      </LegendItem>
      <LegendItem>
        <Img alt="complex" src="full-blue-line.png" />
        <span>Flux provenant d'un OD vers la PDP Iopole</span>
      </LegendItem>
      <LegendItem>
        <Img alt="complex" sx={{width: 20}} src="status-icon.png" />
        <span>Liste des statuts reçus</span>
      </LegendItem>
      <LegendItem>
        <Img alt="complex" sx={{width: 20}} src="code-icon.png" />
        <span>Liste des requêtes HTTP effectuées</span>
      </LegendItem>
    </Legend>
    <Divider orientation="vertical" flexItem sx={{width: 40, margin: '0 10px'}}/>
    <Legend>
      <LegendItem>
        <strong>Émetteur</strong>
        <span style={{marginLeft: 10}}>Opérateur de dématérialisation en émission</span>
      </LegendItem>
      <LegendItem>
        <strong>Acheteur</strong>
        <span style={{marginLeft: 10}}>Opérateur de dematérialisation en réception</span>
      </LegendItem>
      <LegendItem>
        <Img alt="complex" sx={{width: 150}} src="emission.gif" />
        <span>PDP en émission</span>
      </LegendItem>
      <LegendItem>
        <Img alt="complex" sx={{width: 150, marginTop: 0.2}} src="reception.gif" />
        <span>PDP en réception</span>
      </LegendItem>
    </Legend>
  </LegendContainer>);

/**
 *
 * @returns {JSX.Element}
 * @constructor
 */
function MainLegend() {
  const [anchorEl, setAnchorEl] = React.useState(null);

  const handleClick = (event) => {
    setAnchorEl(event.currentTarget);
  };

  const handleClose = () => {
    setAnchorEl(null);
  };

  const open = Boolean(anchorEl);
  const id = open ? 'simple-popover' : undefined;

  return (
    <div>
      <Button aria-describedby={id} sx={{backgroundColor: 'white'}} variant="elevated" onClick={handleClick} endIcon={<LegendToggleIcon/>}>
        Légende
      </Button>
      <Popover
        id={id}
        open={open}
        anchorEl={anchorEl}
        onClose={handleClose}
        anchorOrigin={{
          vertical: 'bottom',
          horizontal: 'left',
        }}
      >
        <Typography sx={{ p: 2 }}>{ParentLegend}</Typography>
      </Popover>
    </div>
  );
}

export const nodes = [
  {
    id: NODES.OD_EMIT.id, // OD EMISSION GENERATE
    type: NODES.OD_EMIT.type,
    position: {x: -700, y: -20}
  },
  {
    id: NODES.PDP_EMIT.id, // PDP EMISSION
    type: NODES.PDP_EMIT.type,
    data: {
      label: 'Iopole emission', // faire un custom
    },
    position: {x: -340, y: 200},
  },
  {
    id: NODES.PDP_RECEIPT.id, // PDP RECEPTION
    type: NODES.PDP_RECEIPT.type, // PDP RECEPTION
    data: {
      label: 'Iopole reception', // faire un custom
    },
    position: {x: 60, y: 160},
  },
  {
    id: NODES.PPF.id,
    type: NODES.PPF.type,
    data: {
      label: 'PPF',
    },
    //className: 'circle',
    /*style: {
      backgroundImage: 'url("publicBuilding.png");',
      color: 'white',
    },*/
    position: {x: -130, y: -100},
    //sourcePosition: Position.Left,
    //targetPosition: Position.Bottom,
  },
  {
    id: NODES.OD_RECEIPT.id, // OD RECEPTION
    type: NODES.OD_RECEIPT.type,
    position: {x: 380, y: -20},
    //draggable: false,
    //     selectable: false,
  },
  {
    id: 'DESCRIPTION', // OD RECEPTION
    type: 'default',
    position: {x: 430, y: 400},
    className: 'annotation',
    style: {
      boxShadow: 'none'
    },
    data: {
      label: (
        <MainLegend/>
      ),
    },
  }
];