import * as React from "react";
import useStateRef from "../common/useStateRef";
import moment from "moment/moment";
import httpService, {
  generateInvoice,
  generateInvoiceFromJson,
  generateMultipleInvoiceFromJson,
  getAxiosClient, getDownloadEndpoint
} from "../services/HttpService";
import Box from "@mui/material/Box";
import Accordion from "@mui/material/Accordion";
import AccordionSummary from "@mui/material/AccordionSummary";
import ExpandMoreIcon from "@mui/icons-material/ExpandMore";
import Typography from "@mui/material/Typography";
import SinglePagePDFViewer from "../common/single-page";
import AccordionDetails from "@mui/material/AccordionDetails";
import fileDownload from 'js-file-download';
import Button from "@mui/material/Button";
import vkbeautify from "vkbeautify";
import {pdfjs} from "react-pdf";
import pdfjsWorker from "pdfjs-dist/build/pdf.worker.entry";
import {toast} from 'react-toastify';
import {CircularProgress} from "@mui/material";
import IopValidationDetails from "./IopValidationDetails";

pdfjs.GlobalWorkerOptions.workerSrc = pdfjsWorker;

export default function IopGeneration() {
  const [expanded, setExpanded] = React.useState("none");
  const [getGenMultiple, setGenMultiple] = React.useState("");
  const [getListGenerationResult, setListGenerationResult] = useStateRef([]);

  const handleNewFile = (event) => {
    console.log("New file to analyse uploaded (file : " + event.detail.sourceFile + ")");
    executeGenerationFromFile(event.detail);
  }

  const handleNewJson = (event) => {
    console.log("New json to analyse invoiceId : " + event.detail.invoiceData.invoiceId + ")");
    executeGenerationFromJson(event.detail);
  }

  React.useEffect(() => {
    document.addEventListener('event/file-uploaded/generate', handleNewFile);
    document.addEventListener('event/json/generate', handleNewJson);
    return () => {
      document.removeEventListener('event/file-uploaded/generate', handleNewFile);
      document.removeEventListener('event/json/generate', handleNewJson);
    };
  }, []);

  const executeGenerationFromJson = async (sourceJsonData) => {
    const baseData = {
      id : sourceJsonData.invoiceData.invoiceId + Math.floor(Math.random() * 10000000),
      running: true,
      label: sourceJsonData.invoiceData.invoiceId,
      time: moment().format('DD/MM/YYYY HH:mm:ss'),
      sourceJsonData: sourceJsonData.invoiceData
    };

    //add list entry at running state
    setListGenerationResult([baseData, ...getListGenerationResult()]);

    console.log("Invoice generation request sent (id : " + baseData.id + ")");
    try {
      const result = await generateInvoiceFromJson(sourceJsonData);

      console.log('Invoice generation result received (id : ' + baseData.id + ")", result);
      await handleGenerationResult(baseData, result);

    } catch (error) {
      toast.error('Failure when trying to generate invoice from json!');
    }
  }

  const executeGenerationFromFile = async (sourceFileData) => {
    const baseData = {
      id : sourceFileData.fileid,
      running: true,
      label: sourceFileData.sourceFile,
      time: moment().format('DD/MM/YYYY HH:mm:ss'),
    };

    //add list entry at running state
    setListGenerationResult([baseData, ...getListGenerationResult()]);

    console.log("Invoice generation request sent (file : " + sourceFileData.sourceFile + ", id : " + baseData.id + ")");
    try {
      const result = await generateInvoice(baseData.id);

      console.log('Invoice generation result received (id : ' + baseData.id + ")", result);
      await handleGenerationResult(baseData, result);

    } catch (error) {
      toast.error('Failure when trying to generate invoice from json!');
    }
  }

  const handleGenerationResult = async (baseData, result) => {
    const items = getListGenerationResult().map(item => {
      if (item.id === baseData.id) {
        return  {
          id : baseData.id,
          running: false,
          failure: result?.failure,
          errorDetails: result?.errorDetails || result?.cause,
          label: baseData.label,
          time: moment().format('DD/MM/YYYY HH:mm:ss'),
          ext: "PDF",
          sourceJsonData: baseData?.sourceJsonData,
          ...result,
          generatedFacturXFileid: result.failure ? undefined :
            httpService.getDownloadEndpoint() + result.generatedFacturXFileid,
          generatedCiiFileid:(result.failure || (!result.generatedCiiFileid)) ? undefined :
            httpService.getDownloadEndpoint() + result.generatedCiiFileid,
          generatedUblFileid: (result.failure || (!result.generatedUblFileid)) ? undefined :
            httpService.getDownloadEndpoint() + result.generatedUblFileid,
          errorCause: (result?.cause ? vkbeautify.json(result?.cause) : null),
          extractedData: ((result?.invoiceValidation?.invoiceXmlData)
            ? vkbeautify.xml(result?.invoiceValidation?.invoiceXmlData)
            : (result?.cause?.pipelineResult?.invoiceContext?.invoiceXmlData) ?
              vkbeautify.xml(result?.cause?.pipelineResult?.invoiceContext?.invoiceXmlData) : null),
         };
      } else {
        return item;
      }
    });
    setListGenerationResult(items);
  }

  const handleFirstLevelExpand = (panel) => (event, isExpanded) => {
    setExpanded(isExpanded ? panel : false);
  };

  async function buttonDownloadClicked(event, fileName, fileEndpoint) {
     getAxiosClient().get(fileEndpoint, {
        responseType: 'blob',
      })
        .then((res) => {
          fileDownload(res.data, fileName )
        });

    event.stopPropagation();
  }

  function renderResult(item) {
    if (item.failure) {
      return <Typography sx={{width: '10%', fontSize: '18px', fontWeight: 'bold', flexShrink: 0, color: 'red'}}>
        Server Failure
      </Typography>;
    }
    else if (item.running) {
      return <Typography sx={{width: '10%', fontSize: '18px', fontWeight: 'bold', flexShrink: 0, color: 'blue'}}>
        Running
      </Typography>;
    } else {
      if (item?.valid === false) {
        return <Typography
          sx={{width: '10%', fontSize: '18px', fontWeight: 'bold', flexShrink: 0, color: 'red'}}>
          Invalid
        </Typography>;
      } else {
        return <Typography
          sx={{width: '10%', fontSize: '18px', fontWeight: 'bold', flexShrink: 0, color: 'green'}}>
          Generated
        </Typography>;
      }
    }
  }

  function populateErrorDetails(item) {
    return  <Box component="ul" aria-labelledby="category-a" sx={{ pl: 2 }}>
      <Typography sx={{width: '100%', flexShrink: 0}}>
        <b>Message :</b>
        <div>
          {(typeof item?.errorDetails?.message === 'string') ? item?.errorDetails?.message : null}
        </div>
      </Typography>
      <Typography sx={{width: '100%', flexShrink: 0}}>
        <b>Details :</b>
        <div>
          {(item?.errorDetails != null && typeof item?.errorDetails == 'object')
              ? <pre><code id="extractedError">{vkbeautify.json(item?.errorDetails)}</code></pre> : "No details"}
        </div>
      </Typography>
    </Box>;
  }

  function showDownloadPackBtn(result, btnName, number) {
    if (result.sourceJsonData && result?.failure !== true) {
      const filename = result.label + "_" + number + ".zip";
      return <Button variant="outlined"
                     color="success"
                     disabled={!!(result.failure || result.running)}
                     sx={{marginLeft: '5px'}}
                     onClick={async (e) => {
                       setGenMultiple(result.id + number);
                       e.stopPropagation();
                       const resultMultiple = await generateMultipleInvoiceFromJson(result.sourceJsonData, number);
                       if ((!resultMultiple?.failure) || resultMultiple?.failure === false) {
                         await buttonDownloadClicked(e, filename, resultMultiple.failure ? undefined :
                           httpService.getDownloadEndpoint() + resultMultiple.generatedFileid);
                       } else {
                         toast.error('Failure when trying to generate invoice pack !');
                       }
                       setGenMultiple("");
                     }}
      > {(getGenMultiple === result.id + number) ? <CircularProgress size={20} /> : btnName}</Button>
    }
    return null;
  }

  function drawDownloadBtnList(result) {
    if (! result.running && !result.failure && !(result?.cause?.pipelineResult?.isValid === false)) {
      return <Typography sx={{width: '50%', flexShrink: 0, display: 'flex', justifyContent: 'flex-end'}}>
        {showDownloadPackBtn(result, "Pack (x10)", 10)}
        {showDownloadPackBtn(result, "Pack (x50)", 50)}
        {showDownloadBtn(result, "UBL", result?.generatedUblFileid)}
        {showDownloadBtn(result, "CII", result?.generatedCiiFileid)}
        {showDownloadBtn(result, "FacturX", result.generatedFacturXFileid)}
      </Typography>
    } else {
      return null;
    }
  }

  function showDownloadBtn(result, btnName, downloadEndpoint) {
    if (downloadEndpoint) {
      const filename = result.generatedFacturXFileid === downloadEndpoint ? result.label + ".pdf"
        : result.label + (result.generatedCiiFileid === downloadEndpoint? "-cii" : "-ubl") + ".xml";
      return <Button variant="outlined"
                color="success"
                disabled={!!(result.failure || result.running)}
                sx={{ marginLeft: '5px' }}
                onClick={(e) => buttonDownloadClicked(e, filename, downloadEndpoint)}
        >{btnName}</Button>
    }
    return null;
  }

  return (
    <Box sx={{width: '80%', borderBottom: 0, marginLeft: '10%', marginRight: '10%', mb: 10}}>
      {getListGenerationResult() &&
        getListGenerationResult().map((result, i) => (
          <Accordion expanded={!result.running && (expanded === 'panel' + i)}
                     onChange={handleFirstLevelExpand('panel' + i)}>
            <AccordionSummary
              expandIcon={result.running ? null : <ExpandMoreIcon/>}
              aria-controls="panel1bh-content"
              id="panel1bh-header"
            >
              {renderResult(result)}
              <Typography sx={{width: '15%', flexShrink: 0}}>
                {result.label}
              </Typography>
              <Typography sx={{width: '20%', flexShrink: 0}}>
                {result.time}
              </Typography>
              {drawDownloadBtnList(result)}
            </AccordionSummary>
            <AccordionDetails>
              {(result.running || (expanded !== 'panel' + i)) ? null :
                (result.failure || (result?.cause && !(result?.cause?.pipelineResult))) ? populateErrorDetails(result) :
                  result?.cause?.pipelineResult?.isValid === false ?
                    <IopValidationDetails item={result}/> :
                    <SinglePagePDFViewer pdf={result.generatedFacturXFileid}/>}
            </AccordionDetails>
          </Accordion>
        ))}
    </Box>
  );
}