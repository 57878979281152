import {NODES} from './nodeEnum';
import {MarkerType} from 'reactflow';

// make custom edges

export const EDGES = {
  OD_EMIT_TO_PDP_EMIT: {
    id: `edge-OD_EMIT_TO_PDP_EMIT`,
    source: NODES.OD_EMIT.id, // ID of the source node
    target: NODES.PDP_EMIT.id, // ID of the target node
    type: 'customInvoiceEmitEdge', // Type of edge, 'smoothstep' for a curved edge
    markerEnd: {
      type: MarkerType.ArrowClosed,
      width: 20,
      height: 10,
      color: 'black'
    }
  },
  PDP_EMIT_TO_OD_EMIT: { // STATUS
    id: `edge-PDP_EMIT_TO_OD_EMIT`,
    source: NODES.PDP_EMIT.id, // ID of the source node
    sourceHandle: NODES.PDP_EMIT.statusHandleId,
    target: NODES.OD_EMIT.id, // ID of the target node
    type: 'customEmitStatusEdge', // Type of edge, 'smoothstep' for a curved edge
    animated: true,
    markerEnd: {
      type: MarkerType.ArrowClosed,
      width: 20,
      height: 10,
      color: 'black'
    },
    data: {
      label: 'Status'
    }
  },
  PDP_EMIT_TO_PDP_RECEIPT: {
    id: `edge-PDP_EMIT_TO_PDP_RECEIPT`,
    source: NODES.PDP_EMIT.id, // ID of the source node
    target: NODES.PDP_RECEIPT.id, // ID of the target node
    type: 'default', // Type of edge, 'smoothstep' for a curved edge
    style: {
      strokeWidth: 2,
      stroke: '#005EFF'
    }
  },
  PDP_RECEIPT_TO_OD_RECEIPT: {
    id: `edge-PDP_RECEIPT_TO_OD_RECEIPT`,
    source: NODES.PDP_RECEIPT.id, // ID of the source node
    target: NODES.OD_RECEIPT.id, // ID of the target node
    type: 'customInvoiceReceivedEdge', // Type of edge, 'smoothstep' for a curved edge
    animated: true,
    markerEnd: {
      type: MarkerType.ArrowClosed,
      width: 28,
      height: 10,
      color: 'black'
    }
  },
  OD_RECEIPT_TO_PDP_RECEIPT: {
    id: `edge-OD_RECEIPT_TO_PDP_RECEIPT`,
    source: NODES.OD_RECEIPT.id,
    targetHandle: NODES.PDP_RECEIPT.statusHandleId,
    target: NODES.PDP_RECEIPT.id,
    type: 'customReceivedStatusEdge',
    markerEnd: {
      type: MarkerType.ArrowClosed,
      width: 20,
      height: 10,
      color: 'black'
    }
  },
  PDP_EMIT_TO_PPF: {
    id: `edge-PDP_EMIT_TO_PPF`,
    source: NODES.PDP_EMIT.id,
    sourceHandle: NODES.PDP_EMIT.statusPpfHandleId,
    target: NODES.PPF.id,
    type: 'customPpfStatusEdge',

    markerEnd: {
      type: MarkerType.ArrowClosed,
      width: 20,
      height: 10,
      color: 'black'
    }
  }
}