import React, { memo } from 'react';
import {Handle, Position} from 'reactflow';
import { styled } from "@mui/material/styles";
import {NODES} from './nodeEnum';

import iopoleIcon from '../../assets/logo_iopole_white.png'
import securiboxIcon from '../../assets/securibox_logo.svg'

import userService from "../../services/UserService";

const currentConfig = {
  icon: iopoleIcon,
  title: 'Emission'
}

function IopPdpEmit({ data, isConnectable}) {
    const user = userService.getUser()?.email;
    if (user && user.toUpperCase().includes('SECURIBOX')) {
      currentConfig.icon = securiboxIcon;
      currentConfig.title = "Simulateur PPF";
    }

    const StyledPdpNode = styled('div')(
    () => `
        background: linear-gradient(-45deg, #00F6FF, #00B4FF, #0098FF, #005EFF, #0098FF, #00B4FF);
        background-size: 400% 400%;
        animation: gradient 7s ease infinite;
        box-shadow: 0 4px 6px -1px rgb(0 0 0 / 15%), 0 2px 4px -1px rgb(0 0 0 / 8%);
        border-radius: 5px;
        text-align: center;
        width: 180px;
        font-size: 16px;
        padding: 10px;
        color: white;
        font-family: Helvetica;
        display: block;
        .logo {
          width: 102px; /* Adjust the size of the logo as needed */
          height: 23px;
        }
        @keyframes gradient {
          0% {
            background-position: 0% 50%;
          }
          50% {
              background-position: 100% 50%;
          }
          100% {
              background-position: 0% 50%;
          }
        }`
    );

  const handleStylePpfRight = { left: 120 };
  const handleStyleOdLeft = { left: 55 };

  const Logo = styled('img')`
    width: 100%;
    height: 100%;
    margin-left: 27px;
`;

  return (
    <>
      <Handle
        type="source"
        position={Position.Right}
        style={{ background: '#555' }}
        onConnect={(params) => console.log('handle onConnect', params)}
        isConnectable={isConnectable}
      />
      <Handle
        type="target"
        position={Position.Left}
        style={{ background: '#555' }}
        onConnect={(params) => console.log('handle onConnect', params)}
        isConnectable={isConnectable}
      />
      <Handle
        type="source"
        id={NODES.PDP_EMIT.statusHandleId}
        style={handleStyleOdLeft}
        position={Position.Top}
        onConnect={(params) => console.log('handle onConnect', params)}
        isConnectable={isConnectable}
      />
      <Handle
        type="source"
        id={NODES.PDP_EMIT.statusPpfHandleId}
        style={handleStylePpfRight}
        position={Position.Top}
        onConnect={(params) => console.log('handle onConnect', params)}
        isConnectable={isConnectable}
      />
      <div>
        <StyledPdpNode>
          <div className="logo">
            <Logo src={currentConfig.icon} alt="Logo" />
          </div>
          <span style={{fontSize: 12}}>{currentConfig.title}</span>
        </StyledPdpNode>
      </div>
    </>
  );
}
export default memo(IopPdpEmit);
