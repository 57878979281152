import * as React from 'react';
import Accordion from '@mui/material/Accordion';
import AccordionDetails from '@mui/material/AccordionDetails';
import AccordionSummary from '@mui/material/AccordionSummary';
import Typography from '@mui/material/Typography';
import Container from '@mui/material/Container';
import ExpandMoreIcon from '@mui/icons-material/ExpandMore';
import IopStatus from './iopStatus';

import moment from 'moment';
import Grid from '@mui/material/Grid';
import Box from "@mui/material/Box";
import IconButton from '@mui/material/IconButton';

import SyntaxHighlighter from 'react-syntax-highlighter';
import { github } from 'react-syntax-highlighter/dist/esm/styles/hljs';
import {ContentCopy} from "@mui/icons-material";

moment.locale('fr');

export default function IopValidationDetails({item}) {
  const [expandedSecond, setExpandedSecond] = React.useState("none");

  const handleSecondLevelExpand = (panel) => (event, isExpanded) => {
    setExpandedSecond(isExpanded ? panel : false);
  };

  function handleCopyClick(event, dataToCopy) {
    navigator.clipboard.writeText(dataToCopy);
    event.stopPropagation();
  }

  function renderValid(item) {
    return <Box sx={{ flexGrow: 1 }}>
      {populateInvoiceDetail(item)}
      {item?.invoiceSignatures?.isSigned ? populateSignatureDetail(item.invoiceSignatures) : null}
      {populateXmlContent(item)}
    </Box>
  }

  function populateInvoiceDetail(item) {
    return  <Accordion expanded={expandedSecond === 'panelInvoiceDetails'} onChange={handleSecondLevelExpand('panelInvoiceDetails')}>
      <AccordionSummary
        expandIcon={<ExpandMoreIcon/>}
        aria-controls="panel1bh-content"
        id="panel1bh-header"
      >
        <Typography sx={{width: '80%', fontSize: '14px', flexShrink: 0, fontWeight: 'bold', textTransform: 'uppercase'}}>
          Invoice Details
        </Typography>
      </AccordionSummary>
      <AccordionDetails>
        <Grid container spacing={2}>
          <Grid item xs={6} md={8}>
            <Box
              id="category-a"
              sx={{ fontSize: '12px', fontWeight: 'bold', textTransform: 'uppercase' }}
            >
              Format
            </Box>
            <Box component="ul" aria-labelledby="category-a" sx={{ pl: 2 }}>
              <Typography sx={{width: '80%', flexShrink: 0}}>
                Format : {item.invoiceValidation.invoiceFormat}
              </Typography>
              <Typography sx={{width: '80%', flexShrink: 0}}>
                Flavor : {item.invoiceValidation.invoiceFlavor}
              </Typography>
            </Box>
            {isFrenchInvoice(item) ? populateUncityKey(item): ''}
            {isFrenchInvoice(item) ? populateRoutingKey(item): ''}
          </Grid>
          <Grid item xs={6} md={4}>
            <Box
              id="category-b"
              sx={{ fontSize: '12px', fontWeight: 'bold', textTransform: 'uppercase' }}
            >
              Invoice
            </Box>
            <Box component="ul" aria-labelledby="category-b" sx={{ pl: 2 }}>
              <Typography sx={{width: '80%', flexShrink: 0}}>
                Id : {item.invoiceBusinessData.invoiceId}
              </Typography>
              <Typography sx={{width: '80%', flexShrink: 0}}>
                Date : {item.invoiceBusinessData.invoiceDate}
              </Typography>
              <Typography sx={{width: '80%', flexShrink: 0}}>
                Code : {item.invoiceBusinessData.type.value} ({item.invoiceBusinessData.type.code})
              </Typography>
            </Box>
            <Box
              id="category-b"
              sx={{ fontSize: '12px', fontWeight: 'bold', textTransform: 'uppercase' }}
            >
              Seller
            </Box>
            <Box component="ul" aria-labelledby="category-b" sx={{ pl: 2 }}>
              <Typography sx={{width: '80%', flexShrink: 0}}>
                Name : {item.invoiceBusinessData.seller.name}
              </Typography>
              {populatePartyData(item.invoiceBusinessData.seller)}
            </Box>
            <Box
              id="category-a"
              sx={{ fontSize: '12px', fontWeight: 'bold', textTransform: 'uppercase' }}
            >
              Buyer
            </Box>
            <Box component="ul" aria-labelledby="category-b" sx={{ pl: 2 }}>
              <Typography sx={{width: '80%', flexShrink: 0}}>
                Name : {item.invoiceBusinessData.buyer.name}
              </Typography>
              {populatePartyData(item.invoiceBusinessData.buyer)}
            </Box>
          </Grid>
        </Grid>
      </AccordionDetails>
    </Accordion>
  }

  function isFrenchInvoice(item) {
    return (item.invoiceBusinessData.seller.country === 'FR' && item.invoiceBusinessData.buyer.country === 'FR');
  }

  function computeElectronicAddress(electronicAddress) {
    if (!electronicAddress || !(electronicAddress?.value))
      return '';

    return (!electronicAddress?.scheme) ? electronicAddress.value : electronicAddress.scheme + ':' + electronicAddress.value;
  }

  function populatePartyData(party) {
    if (party.country === "FR") {
      return <Box>
        <Typography sx={{width: '80%', flexShrink: 0}}>
          Siren : {party.siren}
        </Typography>
        <Typography sx={{width: '80%', flexShrink: 0}}>
          Siret : {party.siret}
        </Typography>
      </Box>
    } else {
      const id = party?.identifiers?.filter(id => id.type === "PARTY_LEGAL_IDENTIFIER")?.at(0);
      return <Box>
        <Typography sx={{width: '80%', flexShrink: 0}}>
          Legal Identifier : {id?.value ? id.value : ''}
        </Typography>
        <Typography sx={{width: '80%', flexShrink: 0}}>
          VAT Number : {party?.vatNumber ? party.vatNumber: ''}
        </Typography>
      </Box>
    }
  }

  function populateUncityKey(item) {
   return  <Box>
     <Box
      id="category-a"
      sx={{ fontSize: '12px', fontWeight: 'bold', textTransform: 'uppercase' }}
    >
      Unicity Key
    </Box>
    <Box component="ul" aria-labelledby="category-a" sx={{ pl: 2 }}>
      <Typography sx={{width: '80%', flexShrink: 0}}>
        Invoice Id : {item.invoiceValidation.invoiceKeys.unicityKey?.invoiceId}
      </Typography>
      <Typography sx={{width: '80%', flexShrink: 0}}>
        Siren : {item.invoiceValidation.invoiceKeys.unicityKey?.identifier}
      </Typography>
      <Typography sx={{width: '80%', flexShrink: 0}}>
        Year : {item.invoiceValidation.invoiceKeys.unicityKey?.year}
      </Typography>
    </Box>
   </Box>
  }

  function populateRoutingKey(item) {
    return  <Box>
      <Box
        id="category-b"
        sx={{ fontSize: '12px', fontWeight: 'bold', textTransform: 'uppercase' }}
      >
        Routing Key
      </Box>
      <Box component="ul" aria-labelledby="category-b" sx={{ pl: 2 }}>
        <Typography sx={{width: '80%', flexShrink: 0}}>
          Electronic Address : {computeElectronicAddress(item.invoiceValidation?.invoiceKeys?.routingKey?.electronicAddress)}
        </Typography>
        <Typography sx={{width: '80%', flexShrink: 0}}>
          Siren : {item.invoiceValidation.invoiceKeys.routingKey?.siren}
        </Typography>
        <Typography sx={{width: '80%', flexShrink: 0}}>
          Siret : {item.invoiceValidation.invoiceKeys.routingKey?.siret}
        </Typography>
        <Typography sx={{width: '80%', flexShrink: 0}}>
          Routing Code : {item.invoiceValidation.invoiceKeys.routingKey?.routingCode}
        </Typography>
      </Box>
    </Box>
  }

  function populateSignatureDetail(signaturesResult) {
    return <Accordion expanded={expandedSecond === 'panelSignatureDetails'} onChange={handleSecondLevelExpand('panelSignatureDetails')}>
      <AccordionSummary
        expandIcon={<ExpandMoreIcon/>}
        aria-controls="panel1bh-content"
        id="panel1bh-header"
      >
        <Typography sx={{width: '80%', fontSize: '14px', flexShrink: 0, fontWeight: 'bold', textTransform: 'uppercase'}}>
          {signaturesResult.isValid ? "Signature Details" : "Signature Error Details"}
        </Typography>
      </AccordionSummary>
      <AccordionDetails>
        <Grid container spacing={2}>
          <Grid item xs={8} md={8}>
            <Box
              id="category-a"
              sx={{ fontSize: '12px', fontWeight: 'bold', textTransform: 'uppercase' }}
            >
              General
            </Box>
            <Box component="ul" aria-labelledby="category-a" sx={{ pl: 2 }}>
              <Typography sx={{width: '80%', flexShrink: 0}}>
                Signed : {signaturesResult.isSigned ? "Yes" : "No"}
              </Typography>
              <Typography sx={{width: '80%', flexShrink: 0}}>
                Valid : {signaturesResult.isValid ? "Yes" : "No"}
              </Typography>
              <Typography sx={{width: '80%', flexShrink: 0}}>
                Count : {signaturesResult.signatures ? signaturesResult.signatures.length : 0}
              </Typography>
            </Box>
            {signaturesResult.signatures &&
              signaturesResult.signatures.map((sig, i) => (
                <Box sx={{mt: 2}}>
                  <Box
                    id="category-a"
                    sx={{ fontSize: '12px', fontWeight: 'bold', textTransform: 'uppercase' }}
                  >
                    Signature #{i+1}
                  </Box>
                  <Box component="ul" aria-labelledby="category-a" sx={{ pl: 2 }}>
                    <Typography sx={{width: '80%', flexShrink: 0}}>
                      Valid : {sig.isValid ? "Yes" : "No"}
                    </Typography>
                    <Typography sx={{width: '80%', flexShrink: 0}}>
                      Indication : {sig.indication} {sig?.subIndication ? " / " + sig.subIndication : ""}
                    </Typography>
                    <Typography sx={{width: '80%', flexShrink: 0}}>
                      Signed By : {sig.signedBy}
                    </Typography>
                    <Typography sx={{width: '80%', flexShrink: 0}}>
                      Signing Time : {new Date(sig.signingTime).toLocaleString("fr")}
                    </Typography>
                    <Typography sx={{width: '80%', flexShrink: 0}}>
                      Signature Format : {sig.signatureFormat}
                    </Typography>
                    <Typography sx={{width: '80%', flexShrink: 0}}>
                      Signature Qualification : {sig.signatureQualification}
                    </Typography>
                  </Box>
                  {!sig.isValid && sig?.validationErrors ?
                    populateSignatureErrors(sig.validationErrors) : null}
                </Box>
              ))}
          </Grid>
        </Grid>
      </AccordionDetails>
    </Accordion>
  }

  function populateXmlContent(item) {
    return <Accordion expanded={expandedSecond === 'panelXml'} onChange={handleSecondLevelExpand('panelXml')}>
      <AccordionSummary
        expandIcon={<ExpandMoreIcon/>}
        aria-controls="panel1bh-content"
        id="panel1bh-header"
      >
        <Typography sx={{width: '95%', mt:1, fontSize: '14px', flexShrink: 0, fontWeight: 'bold', textTransform: 'uppercase'}}>
          Xml Content
        </Typography>
        <IconButton id={"xml-copy-button"}>
          <ContentCopy fontSize="small"  onClick={(e) => handleCopyClick(e, item.extractedData)} />
        </IconButton>
      </AccordionSummary>
      <AccordionDetails>
        <Box component="ul" aria-labelledby="category-b" sx={{ pl: 2 }}>
          <SyntaxHighlighter language="xml" style={github}>
            {item.extractedData}
          </SyntaxHighlighter>
        </Box>
      </AccordionDetails>
    </Accordion>
  }

  function renderInvalid(item) {
    return <Box sx={{ flexGrow: 1 }}>
      <Box
        id="category-a"
        sx={{ fontSize: '16px', fontWeight: 'bold', textTransform: 'uppercase', mb: 2}}
      >
        Error Details
      </Box>
      {!item.cause.causedBy ?
        renderInvalidBusinessError(item) :
        populateGenericDetail(item)}
      {item.extractedData ? populateXmlContent(item) : ''}
    </Box>
  }

  function populateSignatureErrors(validationErrors) {
    return <Box sx={{mt: 1, ml: 2}}>
      <Box
        id="category-a"
        sx={{ fontSize: '12px', fontWeight: 'bold', textTransform: 'uppercase' }}
      >
        Errors
      </Box>
      <Box component="ul" aria-labelledby="category-a" sx={{ pl: 2 }}>
        {validationErrors && validationErrors.map((err, i) => (
          <Typography sx={{width: '100%', flexShrink: 0}}>
            Error : {err}
          </Typography>
          ))}
      </Box>
    </Box>
  }

  function populatePdfA3Detail(pdfA3ValidationResult) {
    return (
      <div>
        <Accordion expanded={expandedSecond === 'panelPdfA3'} onChange={handleSecondLevelExpand('panelPdfA3')}>
          <AccordionSummary
            expandIcon={<ExpandMoreIcon/>}
            aria-controls="panel1bh-content"
            id="panel1bh-header"
          >
            <Typography sx={{width: '80%', fontSize: '14px', flexShrink: 0, fontWeight: 'bold', textTransform: 'uppercase'}}>
              Invalid Pdf A3 Data Details (Flavor : {pdfA3ValidationResult.pdfaFlavour})
            </Typography>
            <Typography sx={{width: '20%', fontSize: '14px', flexShrink: 0, fontWeight: 'bold', textTransform: 'uppercase'}}>
              {pdfA3ValidationResult.failedAssert.length} Errors
            </Typography>
          </AccordionSummary>
          <AccordionDetails>
            <Box sx={{ flexGrow: 1 }}>
              {pdfA3ValidationResult.failedAssert &&
                pdfA3ValidationResult.failedAssert.map((item, i) => (
                  <Box sx={{mt: 2}}>
                    <Typography sx={{width: '95%', flexShrink: 0}}>
                      <b>Rule : </b> {item.ruleClause} {item.ruleSpecification}
                    </Typography>
                    <Typography sx={{width: '95%', flexShrink: 0}}>
                      <b>Message : </b> {item.message}
                    </Typography>
                    <Typography sx={{width: '95%', flexShrink: 0}}>
                      <b>Location : </b> {item.locationContext}
                    </Typography>
                  </Box>
                ))}
            </Box>
          </AccordionDetails>
        </Accordion>
      </div>)
  }

  function populatePdfXmpDetail(pdfXmpValidationResult) {
    return (
      <div>
        <Accordion expanded={expandedSecond === 'panelPdfXmp'} onChange={handleSecondLevelExpand('panelPdfXmp')}>
          <AccordionSummary
            expandIcon={<ExpandMoreIcon/>}
            aria-controls="panel1bh-content"
            id="panel1bh-header"
          >
            <Typography sx={{width: '80%', fontSize: '14px', flexShrink: 0, fontWeight: 'bold', textTransform: 'uppercase'}}>
              Invalid Pdf Xmp Data Details
            </Typography>
            <Typography sx={{width: '20%', fontSize: '14px', flexShrink: 0, fontWeight: 'bold', textTransform: 'uppercase'}}>
              {pdfXmpValidationResult.length} Errors
            </Typography>
          </AccordionSummary>
          <AccordionDetails>
            <Box sx={{ flexGrow: 1 }}>
              {pdfXmpValidationResult &&
                pdfXmpValidationResult.map((item, i) => (
                  <Box sx={{mt: 2}}>
                    <Typography sx={{width: '95%', flexShrink: 0}}>
                      <b>Name : </b>{item.name}
                    </Typography>
                    <Typography sx={{width: '95%', flexShrink: 0}}>
                      <b>Error : </b>{item.errorDetail}
                    </Typography>
                    <Typography sx={{width: '95%', flexShrink: 0}}>
                      <b>xPath : </b>{item.xPath}
                    </Typography>
                  </Box>
                ))}
            </Box>
          </AccordionDetails>
        </Accordion>
      </div>)
  }

  function populateSchemaDetail(schemaValidationErrors) {
    return (
      <div>
        <Accordion expanded={expandedSecond === 'panelSchema'} onChange={handleSecondLevelExpand('panelSchema')}>
          <AccordionSummary
            expandIcon={<ExpandMoreIcon/>}
            aria-controls="panel1bh-content"
            id="panel1bh-header"
          >
            <Typography sx={{width: '80%', fontSize: '14px', flexShrink: 0, fontWeight: 'bold', textTransform: 'uppercase'}}>
              Schema Error Details (Xsd Validation)
            </Typography>
            <Typography sx={{width: '20%', fontSize: '14px', flexShrink: 0, fontWeight: 'bold', textTransform: 'uppercase'}}>
              {schemaValidationErrors.length} Errors
            </Typography>
          </AccordionSummary>
          <AccordionDetails>
            <Box sx={{ flexGrow: 1 }}>
              {schemaValidationErrors &&
                schemaValidationErrors.map((item, i) => (
                  <Typography sx={{width: '95%', flexShrink: 0, mt: 1}}>
                    {item}
                  </Typography>
                ))}
            </Box>
          </AccordionDetails>
        </Accordion>
      </div>)
  }

  function populateSchematronDetail(schematronValidationErrors) {
    return (
      <div>
        <Accordion expanded={expandedSecond === 'panelSchematron'} onChange={handleSecondLevelExpand('panelSchematron')}>
          <AccordionSummary
            expandIcon={<ExpandMoreIcon/>}
            aria-controls="panel1bh-content"
            id="panel1bh-header"
          >
            <Typography sx={{width: '80%', fontSize: '14px', flexShrink: 0, fontWeight: 'bold', textTransform: 'uppercase'}}>
              Schematron Error Details (semantic Validation)
            </Typography>
            <Typography sx={{width: '20%', fontSize: '14px', flexShrink: 0, fontWeight: 'bold', textTransform: 'uppercase'}}>
              {schematronValidationErrors.length} Errors
            </Typography>
          </AccordionSummary>
          <AccordionDetails>
            <Box sx={{ flexGrow: 1 }}>
              {schematronValidationErrors &&
                schematronValidationErrors.map((item, i) => (
                  <Box sx={{mt: 2}}>
                    <Typography sx={{width: '80%', flexShrink: 0, fontSize: '18px'}}>
                      <b>Message : </b>{item.text}
                    </Typography>
                    <Typography sx={{width: '80%', flexShrink: 0}}>
                      <b>Location : </b>{item.location}
                    </Typography>
                    <Typography sx={{width: '80%', flexShrink: 0}}>
                      <b>Test : </b>{item.test}
                    </Typography>
                  </Box>
                ))}
            </Box>
          </AccordionDetails>
        </Accordion>
      </div>)
  }

  function populateGenericDetail(item) {
    return  <Box component="ul" aria-labelledby="category-a" sx={{ pl: 2 }}>
      <Typography sx={{width: '95%', flexShrink: 0}}>
        <b>Code : </b>{item.code}
      </Typography>
      <Typography sx={{width: '100%', flexShrink: 0}}>
        <b>Details :</b>
        <div>
          <pre><code id="extractedError">{item.errorCause}</code></pre>
        </div>
      </Typography>
    </Box>;
  }

  function renderInvalidBusinessError(item) {
    return <Box sx={{ flexGrow: 1 }}>
      {item.cause.signaturesResult ? populateSignatureDetail(item.cause.signaturesResult) : ""}
      {item.cause.pdfA3ValidationResult ? populatePdfA3Detail(item.cause.pdfA3ValidationResult) : ""}
      {item.cause.pdfXmpValidationResult ? populatePdfXmpDetail(item.cause.pdfXmpValidationResult.resultError) : ""}
      {item.cause.schemaValidationErrors ? populateSchemaDetail(item.cause.schemaValidationErrors) : ""}
      {item.cause.schematronValidationErrors ? populateSchematronDetail(item.cause.schematronValidationErrors) : ""}
    </Box>
  }

  return (
    <Box sx={{width: '80%', borderBottom: 1, marginLeft: '10%', marginRight: '10%'}}>
      <AccordionDetails>
        <Box sx={{ flexGrow: 1 }}>
          <Container>
            <IopStatus data={item}/>
            {item.valid
              ? renderValid(item)
              : renderInvalid(item)}
          </Container>
        </Box>
      </AccordionDetails>
    </Box>
  );
}