import React, {useEffect, useState} from "react";
import UploadFiles from "../components/uploadFilesComponent";
import IopGeneration from "../components/IopGeneration";
import {Tab, TabList, TabPanel, Tabs} from 'react-tabs';
import 'react-tabs/style/react-tabs.css';
import Box from "@mui/material/Box";

import {Translation, useTranslation} from "react-i18next";
import i18next from 'i18next';

import testTools from 'iop-lab-commons/tools/testTools';
import Form from '@rjsf/core';
import validator from '@rjsf/validator-ajv8';
import {Checkbox} from "@mui/material";
import Button from "@mui/material/Button";
import {toast} from 'react-toastify';

import UploadService from "../services/uploadFilesService";
import {validateInvoice} from '../services/HttpService';
import FileUploadButton from "../components/SimpleFileUploadButton";

const schemas = {
  SIMPLE_BASICWL: {
    schema: require('../schema/SIMPLE_BASICWL.schema.json'),
    ui: {
      en: require('../schema/ui/en/SIMPLE_BASICWL.ui.schema.json'),
      fr: require('../schema/ui/fr/SIMPLE_BASICWL.ui.schema.json'),
      de: require('../schema/ui/de/SIMPLE_BASICWL.ui.schema.json')
    }
  },
  SIMPLE_BASIC: {
    schema: require('../schema/SIMPLE_BASIC.schema.json'),
    ui: {
      en: require('../schema/ui/en/SIMPLE_BASIC.ui.schema.json'),
      fr: require('../schema/ui/fr/SIMPLE_BASIC.ui.schema.json'),
      de: require('../schema/ui/de/SIMPLE_BASIC.ui.schema.json')
    }
  }
};

function Generator() {
  const [options, setOptions] = useState({signInvoice: true});
  const [language, setLanguage] = useState('FRENCH');
  const [invoiceTemplate, setInvoiceTemplate] = useState("SIMPLE_BASICWL");
  const [formData, setFormData] = useState({invoiceDate: new Date().toISOString().split('T')[0]});

  const {t, i18n} = useTranslation();
  const [currentSchema, setCurrentSchema] = useState(loadSchema(invoiceTemplate));
  const [currentUiSchema, setCurrentUiSchema] = useState(loadUiSchema(invoiceTemplate));

  useEffect(() => {
    // Function to handle language change
    const handleLanguageChange = (lng) => {
      handleInvoiceTemplate(invoiceTemplate);
    };

    // Add event listener for language change
    i18next.on('languageChanged', handleLanguageChange);

    // Cleanup function to remove the event listener
    return () => {
      i18next.off('languageChanged', handleLanguageChange);
    };
  }, []);

  function loadSchema(value) {
    return buildTranslatedSchema(schemas[value].schema, schemas[value].ui[i18n.language]);
  }

  function loadUiSchema(value) {
    return schemas[value].ui[i18n.language];
  }

  function handleInvoiceTemplate(value) {
    setInvoiceTemplate(value);
    setCurrentSchema(loadSchema(value));
    setCurrentUiSchema(loadUiSchema(value));
  }

  function buildTranslatedSchema(schema, uiSchema) {
    //translate title and definition
    if (uiSchema['ui:root']) {
      if (uiSchema['ui:root']['ui:title']) {schema.title = uiSchema['ui:root']['ui:title'];}
      if (uiSchema['ui:root']['ui:definitions']) {schema.definitions = uiSchema['ui:root']['ui:definitions'];}
    }

    //translate key
    for (const key in schema.properties) {
      if (uiSchema[key] && uiSchema[key]['ui:title']) {
        schema.properties[key].title = uiSchema[key]['ui:title'];
      }
      // Recursively inject titles for nested properties (e.g., objects)
      if (schema.properties[key].properties && uiSchema[key]) {
        buildTranslatedSchema(schema.properties[key], uiSchema[key]);
      }
      // Handle nested arrays (e.g., items)
      if (schema.properties[key].items && schema.properties[key].items.properties && uiSchema[key].items) {
        buildTranslatedSchema(schema.properties[key].items, uiSchema[key].items);
      }
    }
    return schema;
  }

  async function handleImportData(files) {
    console.log("New file to analyse uploaded (file : " + files[0] + ")");
    const resultUpload = await UploadService.upload(files[0], null);

    if (resultUpload?.data?.fileid) {
      const result = await validateInvoice(resultUpload.data.fileid);
      if (result.valid) {
        result.invoiceBusinessData.type = result.invoiceBusinessData.type.code;
        result.invoiceBusinessData.processType = result.invoiceBusinessData.processType.code;

        if (result.invoiceBusinessData.lines){
          setInvoiceTemplate("SIMPLE_BASIC");
        } else {
          setInvoiceTemplate("SIMPLE_BASICWL")
        }

        setFormData(result.invoiceBusinessData);
        toast.info(t('generate.toast.importSuccess'));
        return;
      } else {
        toast.error(t('generate.toast.importFailed'));
      }
    } else {
      toast.error(t('generate.toast.uploadFailed'));
    }
  }

  function sendGenerateRequest(data) {
    console.log(data);
    const customEvent = new CustomEvent('event/json/generate', {detail: {
        invoiceData: testTools.removeEmptyNodes(data),
        signInvoice: options.signInvoice,
        invoiceLang: language
      }});
    document.dispatchEvent(customEvent);
  }

  const handleRandomData = () => {
    const { invoiceLine, taxDetails, monetary } = testTools.generateRandomAmounts();
    const sellerSiren = Math.floor(100000000 + Math.random() * 900000000);

    const data = {
      "type": 380,
      "processType": "B1",
      "invoiceId": `IOPOLE-INV-${Math.floor(Math.random() * 10000)}`,
      "invoiceDate": new Date().toISOString().split('T')[0],
      "invoiceDueDate": new Date(Date.now() + 7 * 24 * 60 * 60 * 1000).toISOString().split('T')[0],
      "buyer": {
        "name": "Example Buyer",
        "siren": `${Math.floor(100000000 + Math.random() * 900000000)}`,
        "postalAddress": {
          "country": "FR",
          "addressLineOne": "974 avenue du pirée",
          "cityName": "Montpellier",
          "postalCode": "34000",
        }
      },
      "seller": {
        "name": "Example Seller",
        "siren": `${Math.floor(100000000 + Math.random() * 900000000)}`,
        "vatNumber": `${testTools.computeVATNumber(sellerSiren.toString())}`,
        "postalAddress": {
          "country": "FR",
          "addressLineOne": "42 rue des chèvres",
          "cityName": "Perpignan",
          "postalCode": "66000",
        }
      },
      "taxDetails": taxDetails,
      "monetary": monetary
    }

    if (invoiceTemplate !== "SIMPLE_BASICWL") {
      data.lines = [invoiceLine];
    }

    setFormData(data);
  };

  function ObjectFieldTemplate(props) {
    const { id, classNames, style, label, required, description, errors, children, uiSchema } = props;
    const [isOpen, setIsOpen] = useState(true);

    const options = uiSchema?.['ui:options'];
    const flattenObject = uiSchema?.['ui:flattenObject'];

    const toggleOpen = () => {
      setIsOpen(!isOpen);
    };

    if (flattenObject === true) {
      return <div>
        {props.properties.map((element) => {
          // Clone the element to avoid mutating the original
          const clonedElement = React.cloneElement(element.content, {
            schema: {
              ...element.content.props?.schema,
              title: props.title + (element?.content?.props?.schema?.title ? ' ' + element.content.props?.schema?.title : '')
            }
          });

          return (
            <div className='property-wrapper' key={element.name}>
              {clonedElement}
            </div>
          );
        })}
      </div>
    } else {
      return (
        <div>
          {(
            <span style={{cursor: 'pointer', marginRight: '8px'
              , marginTop: options?.style?.marginTop || '2px'
              , marginBottom: options?.style?.marginBottom || '2px'}} onClick={toggleOpen}>
              {isOpen ? '−' : '+'}
            </span>
          )}
          <label htmlFor={id} style={{display: 'inline-block'
            , marginTop: options?.style?.marginTop || '2px'
            , marginBottom: options?.style?.marginBottom || '2px'}}>
            <strong style={{fontSize: options?.style?.fontSize || '20px'}}>{props.title}</strong>
            {required ? '*' : null}
          </label>
          {isOpen ? (
            <div>
              {props.description}
              {props.properties.map((element) => (
                <div className='property-wrapper'>{element.content}</div>
              ))}
            </div>
          ) : null}
        </div>)
    }
  }

  return (
    <>
      <Box sx={{mt: 1}}>
        <Tabs>
          <Box sx={{width: '60%', borderBottom: 0, marginLeft: '20%', marginRight: '20%', mt: 0}}>
            <TabList>
              <Tab>
                <Translation>{t => <span>{"  " + t('generate.fromManual')}</span>}</Translation>
              </Tab>
              <Tab>
                <Translation>{t => <span>{"  " + t('generate.fromFile')}</span>}</Translation>
              </Tab>
            </TabList>
          </Box>
          <TabPanel>
            <Box sx={{width: '60%', borderBottom: 0, marginLeft: '20%', marginRight: '20%', mt: 0}}>
              <div style={{ display: 'flex', alignItems: 'center' }}>
                <Checkbox
                  id={"signInvoice"}
                  disabled={true}
                  checked={options.signInvoice}
                  onChange={() => {
                    setOptions(prevOptions => ({
                      ...prevOptions,
                      signInvoice: !prevOptions.signInvoice
                    }));
                  }}
                />
                <label htmlFor="signInvoice">{t('generate.signInvoice')}</label>

                <div style={{ marginLeft: '20px', display: 'flex', alignItems: 'center' }}>
                  <label htmlFor="invoiceTemplate" style={{ marginRight: '5px' }}>{t('generate.invoiceTemplate')}</label>
                  <select
                    id="invoiceTemplate"
                    value={invoiceTemplate}
                    onChange={(e) => handleInvoiceTemplate(e.target.value)}
                  >
                    <option value="SIMPLE_BASICWL">Simple Basic WL</option>
                    <option value="SIMPLE_BASIC">Simple Basic</option>
                  </select>
                </div>

                <div style={{ marginLeft: '20px', display: 'flex', alignItems: 'center' }}>
                  <label htmlFor="language" style={{ marginRight: '5px' }}>{t('generate.pdfLang')}</label>
                  <select
                    id="language"
                    value={language}
                    onChange={(e) => setLanguage(e.target.value)}
                  >
                    <option value="FRENCH">{t('generate.invoiceLang.fr')}</option>
                    <option value="ENGLISH">{t('generate.invoiceLang.en')}</option>
                    <option value="GERMAN">{t('generate.invoiceLang.de')}</option>
                  </select>
                </div>

                <div style={{ marginLeft: '20px', display: 'flex', alignItems: 'center' }}>
                  <button
                    className="btn btn-random-data btn-sm"
                    style={{ display: 'none' }}
                    onClick={handleRandomData}
                    id="contained-button-exec"
                  />
                  <label htmlFor="contained-button-exec">
                    <Button variant="contained" color="primary" component="span">
                      {t('generate.btnRandomData')}
                    </Button>
                  </label>
                </div>
                <div style={{ marginLeft: '20px', display: 'flex', alignItems: 'center' }}>
                  <FileUploadButton
                    btnName={t('generate.btnImportData')}
                    acceptExt='.pdf,.xml,.json'
                    onFilesSelected={handleImportData}
                  />
                </div>
              </div>
              <Form
                schema={currentSchema}
                uiSchema={currentUiSchema}
                validator={validator}
                formData={formData}
                templates={{ ObjectFieldTemplate }}
                onSubmit={
                  (data) => sendGenerateRequest(data?.formData)
                }
              />
            </Box>
          </TabPanel>
          <TabPanel>
            <Translation>{t => <UploadFiles config={{eventName:"generate", acceptExt:".xml,.json",
              noSelection:t('generate.noSelection'), btnName:t('generate.btnGenerate')}}/>}
            </Translation>
          </TabPanel>
          {/*<TabPanel>
            <h2>Any content 2</h2>
          </TabPanel>*/}
        </Tabs>
      </Box>
      <br/><br/>
      <IopGeneration/>
    </>
  );
}

export default Generator;